.sort-container {
    flex-direction: row;
    width: 60%;
    height: 230px;
    padding: 0;
    margin: 10px;
    // display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 12px;
    background-color: white;
}

.sort-view-btn {
    background-color: #5C5AB6;
    padding: 2px 15px;
    color: white;
    border: none;
    border-radius: 4px;
}

.sort-object {
    justify-content: space-between;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
}

.sort-details {
    font-size: 10px;
    padding-left: 5px;
}

.sort-view {
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
    // padding: 10px;

}

.sort-students {
    // padding: 10px;
    font-size: 12px;
    margin-top: 5px;

    svg {
        color: #5C5AB6;
    }
}

.grid {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    font-size: 12px;
}

.sort-teacher {
    background-color: #5C5AB6;
    color: white;
    padding: 3px;
    text-align: center;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
}

.Prerfferd-container {
    font-size: 12px;
    background-color: white;
    // width: 400px;
    // margin-left: 50px;
    padding: 15px 20px;
    margin-top: 20px;
    margin-right: 10px;
    border-radius: 6px;
}

.time-input {
    color: #000;
    font-family: Nunito Sans;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 98.9%;
    /* 12.542px */
}

.purchase-view-btn {
    color: #FFF;
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: none !important;
}

.show-sub-field {
    color: #263238;
    font-family: Nunito Sans;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.sort {

    flex-direction: row;
}

.grid-view {
    flex-direction: row !important;
    flex-wrap: wrap;
}

.list-view {
    flex-direction: column !important;
    flex-wrap: wrap;
}

@keyframes example {
    // 0%   {background-color:red; left:0px; top:0px;}
    // 25%  {background-color:yellow; left:200px; top:0px;}
    // 50%  {background-color:blue; left:200px; top:200px;}
    // 75%  {background-color:green; left:0px; top:200px;}
    // 100% {background-color:red; left:0px; top:0px;}
}

.grid-container {
    align-items: center;
    justify-content: center;
    animation-name: example;
    animation-duration: 4s;
    transition: all;
}

.cart-img-div {
    width: 150px;
    height: 150px;
}

.cart-img {
    width: 150px;
    height: 150px;

}

.showsubject-container {
    background-color: #EAE9FB;
}

.cart-grid {
    // margin-left: 90px !important;
}

.schedule-head {
    padding: 10px;
}

.btnContainer {
    padding-left: 10px;
}

.show-sub-inp {
    &::placeholder {
        color: #263238;
        text-align: center;
        font-family: Nunito Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
}

.btn {
    // padding: 2px;
    border: none;
}

.list-btn,
.grid-btn {
    padding-right: 10px;

    .active {
        background: #98C5EE;
        padding: 0px 4px;
    }
}

.list-grid {
    padding-top: 10px;
    padding-left: 10px;
}

.show-sort {
    color: #263238;
    text-align: center;
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 100% */
}

.show-sub-field {
    color: #263238;
    text-align: center;
    font-family: Nunito Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 100% */
}

.icon-name1 {
    color: white;
    background-color: #5C5AB6;
    height: 43px;

}

.course-teacher-name {
    background-color: #5C5AB6;
    color: white;
}

.course-eng-head {
    color: #000;
    font-family: Nunito Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

@-webkit-keyframes ease1 {
    0% {
        -webkit-transform: scaleY(1.0);
    }

    50% {
        -webkit-transform: scaleY(1.2);
    }

    100% {
        -webkit-transform: scaleY(1.0);
    }
}

.list-container {
    flex-direction: column;
    width: 250px;
    height: 390px;
    padding: 10px 20px;
    margin: 10px;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 12px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 3.569037675857544px 35.69037628173828px 0px #0000001A;

    .sort-teacher-list {
        width: 150px;
    }

    .cart-img {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
}



.student-icon-clr {
    color: #5C5AB6;
}

.student-pgrh {
    color: #263238;
    font-family: Nunito Sans;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.showsubject-main{
    background-color:  #F3F4F8;
}

// countdown
.timer-wrapper {
    display: flex;
    justify-content: center;
  }
  
  .timer {
    font-family: "Montserrat";
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .text {
    color: #aaa;
    font-size: 14px;
  }
  
  .value {
    font-size: 13px;
  }
  
  .card-offer{
    background-color:#342056;
    width: 15%;
    position: relative;
    left: 37px;
    
  }
  .off-p{
    color: #F3F4F8;
    font-family: Arial, sans-serif;
    font-size: 20px;
  }
  .vertical-text {
   
    writing-mode: vertical-lr; 
    text-orientation: mixed;
    transform: rotate(180deg);

}

.offer-modal{


font-family: Arial, sans-serif;
width: 300px;
}

.offer-p1{
    font-size: 20px;
}
.img-show{
    max-height: 50%;
}

.grid-show{
    margin-top: -40px;
    margin-left: 280px;
}
.card-offer-grid{
    background-color:#342056;
    width: 20%;
  
    margin-right: 40px;
}
.card-showsub{
    width: '400px';
     height: '450px' 
}

.FaShoppingCart{
    font-size: 20px;
}


.img-container-show {
    width: 200px; /* Set your desired width */
    height: 200px; /* Set your desired height */
    overflow: hidden; /* Ensure overflow is hidden */
  }
  
  .img-container-show img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* This ensures the image covers the entire container */
  }