 .clients {

     background-color: #5C5AE9;
 }

 .client-head {
     font-family: 'Poppins';
     text-align: center;
     font-weight: bold;
     padding: 27px;
     font-family: sans-serif;
     font-style: normal;
     color: white;
 }

 .client-card {
     width: 250px;
     height: 21rem;
     padding: 20px;
     justify-items: right;
     border: 1px solid #DEDEDE;
     background-color: white;
     border-radius: 5px;
 }

 .client-text,
 .client-body,
 .client-second-body,
 .client-second-text,
 .client-third-body,
 .client-third-tex {
     font-family: 'Poppins';
     text-align: center;
 }

 .client-second {
     font-family: 'Poppins';
     width: 18rem;
     height: 14rem;
     color: white;
     justify-items: right;
     background: #263238;
     box-shadow: 0px 4px 20px rgba(45, 102, 246, 0.2);
     border-radius: 5px;
 }

 .client-third {
     font-family: 'Poppins';
     width: 18rem;
     height: 14rem;
     background-color: white;
     justify-items: right;
     border: 1px solid #DEDEDE;
     border-radius: 5px;
 }


 .cards {
     display: flex;
     padding-top: 59px;
     padding-bottom: 79px;
 }


 .h4 {
     font-weight: 1000;
     padding: 10px;
 }

 .btnn {
     margin-left: 103px;
     width: 130px;
     height: 30px;
     background-color: rgb(101, 101, 165);
     color: white;
     border-radius: 10px;
     border: none;
 }

 .happy_clients {
     font-family: 'Poppins';
     text-align: center;
     color: white;
     font-size: 14px;
 }

 .clients {
     background-color: #5C5AE9;
     //  height: 460px;
     padding-top: 10px;

 }

 .client-img {
     position: absolute;
     top: -39px;
 }

 .card3 {
     margin-right: 120px;
     padding-right: 20px;
 }

 .Rimg3 {
     top: -2pc;
     position: absolute;

     left: 109px;
 }

 .Rimg1,
 .Rimg2,
 .Rimg3 {
     align-items: center;
     border-radius: 50%;
     width: 78px;
     height: 76px;
     padding: 11px;
 }

 .all-client-card {
     justify-content: space-evenly;

     .slick-slider {
         width: 50%;
     }

     .slick-slide {
         :first-child {
             // margin-left: 5px;
         }
     }

     .slick-prev,
     .slick-next {
         display: none !important;
     }

     .slick-dots li.slick-active button:before {
         color: #FFE26A;
         opacity: 1;
     }

     .slick-dots li button:before {
         color: #DEDEDE;
         font-size: 10px;
         opacity: 1;
         z-index: 3;
     }

     .slick-dots li.slick-active button::after {
         content: '';
         width: 11px;
         height: 11px;
         display: flex;
         background-color: white;
         border-radius: 50%;
         position: absolute;
         top: 3px;
         left: 3px;
         z-index: 1;
         border: 1px solid #FFE26A;
     }

     .slick-current {
         .client-card {
             background: #263238;
             border-color: #263238;
             color: white;

             .client-card-footer {
                 background-color: white;
             }
         }
     }
 }

 .client-card-footer {

     width: 100%;
     text-align: center;
     padding: 5px;
 }





 @media only screen and (max-width: 767px) {
     .all-client-card {
         justify-content: space-evenly;

         .slick-slider {
             width: 290%;
         }
     }
 }


 @media only screen and (min-width: 768px) and (max-width: 991px) {
     .all-client-card {
         justify-content: space-evenly;

         .slick-slider {
             width: 100%;
         }
     }
 }




 @media only screen and (min-width: 992px) and (max-width: 1199px) {

     .all-client-card {
         justify-content: space-evenly;

         .slick-slider {
             width: 80%;
         }
     }
 }


 @media only screen and (min-width: 1200px) {

     .all-client-card {
         justify-content: space-evenly;

         .slick-slider {
             width: 50%;
         }
     }

     .happy_clients {
         font-size: 24px;
     }

     .client-text {
         font-size: 24px;
     }
 }