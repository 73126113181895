.btn-addAssign-subject {
    background-color: #5C5AB6;
    border: 1px solid #5C5AB6;
    border-radius: 5px;
    border-width: 5px;
    color: whitesmoke;
    width: 15%;
    margin-left: 50px;
    margin-top: 50px;
}

.main-table-Assignment {
    margin-left: 100px;
    margin-top: 30px;
    border: 1px solid black;
    height: 50px;
    width: 150vh;
}

.tr-assign {

    background-color: #5C5AB6;
    text-align: center;
    font-size: 14px;
    border: 1px solid black;
    height: 30px;
    color: whitesmoke;

}

table {
    border-collapse: collapse;
    width: 80%;

}

// th {
//     width: 400px;
//     background-color: #5C5AB6;


// }

td {
    margin-top: 50px;

}

.td-assign-action {
    padding-right: 100px;
}

.tr2-assign {

    text-align: center;
    font-size: 15px;
    height: 50px;
    margin-top: 50px;

}

.view-assignment {
    height: 20px;
    width: 20px;
    margin-right: 50px;

}

.dropbox-action-assign {
    border: 1px solid whitesmoke;
    outline: none;
    padding-left: 0px;
    font-size: 12px;
    margin-left: 100px;
    width: 100px;
    text-align: center;
    position: fixed;
    letter-spacing: 4px;
    opacity: 1;


}
.dropbox-action-assign-schedule123{
    padding-left: 80px;
}