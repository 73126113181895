.purple-btn {
    align-items: center !important;
    background-color: #5C5AB6 !important;
    border-radius: 56px !important;
    color: #FFFFFF !important;
    text-transform: initial !important;
    height: 35px;
}
.violet-btn {
    font-family: 'Poppins';
    align-items: center !important;
    padding: 8px 12px !important;
    background-color: #5C5AE9 !important;
    border-radius: 56px !important;
    color: #FFFFFF !important;
    height: 35px;
    text-transform: initial !important;
    i {
        color: #FFF;
    }
}
.white-btn {
    font-family: 'Poppins';
    align-items: center !important;
    padding: 8px 12px !important;
    background-color: #FFF !important;
    border-radius: 56px !important;
    color: #5C5AE9 !important;
    text-transform: initial !important;
    height: 35px;
    i {
        color: #5C5AE9;
    }
}

.d-flex {
    display: flex;
}
.d-flex-center-column {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.d-flex-center-row {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.mb200 {
    margin-bottom: 200px;
}
.wp-100 {
    width: 100%;
}
.pr-10 {
    padding-right: 10px;
    }
.pl-20 {
    padding-left: 20px;
} 
.po-ab{
    position: absolute;
}
.pt40{
    padding-top: 40px !important;
}
.pt50{
    padding-top: 50px !important;
}
.pb50{
    padding-bottom: 50px;
}
.pt10 {
    padding-top: 10px;
}
.fw700{
    font-weight: 700;
}
.fs20{
    font-size: 20px;
}
.mr15{
    margin-right: 20px;
}
.p20 {
    padding: 20px;
}
.mb50 {
    margin-bottom: 50px;
}
.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.align-base{
    align-items: baseline;
}
.mb20 {
    margin-bottom: 20px !important;
}
.mt30 {
    margin-top: 30px;
}
.side_heading{
    font-size: 12px;
}
.p-20-30 {
    padding: 20px 30px;
}
.pl-0 {
    padding-left: 0px;
}
.pl-2 {
    padding-left: 2px;
}
.pr-2 {
    padding-right: 2px;
}
.pl-5 {
    padding-left: 5px;
}
.pr-5 {
    padding-right: 5px;
}
.mr-5 {
    margin-right: 5px;
}
.mtp-1 {
    margin-top: 1px;
}
.out-none{
    outline: none;
}
.all-featured-test{
    // margin-top: 33px;
    padding: 0 5%;
    flex-wrap: wrap;
    margin-bottom: 15px;

}
.my-sub{
    margin-bottom: 10px;
}
/* StudentSidebar.css */

/* StudentSidebar.css */

.sidebar {
    width: 250px;
    transition: width 0.3s;
    overflow: hidden;
  }
  
  .closed {
    width: 0;
  }
  
  .toggle-btn {
    display: block;
    margin: 10px;
    background-color: #2196F3;
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
  }
  
  /* Media query for smaller screens */
  @media (max-width: 768px) {
    .sidebar {
      width: 100%;
    }
  
    .closed {
      width: 0;
    }
  }
  
  