.main-addsub-head {
    padding-left: 50px;
    margin-top: 50px;
    font-size: 22px;
    float: left;
    font-weight: 700;
    margin-left: 120vh;
}

.input-fields-addsub {
    // margin-top: 80px;
    // margin-left: 600px;
    height: auto;
    width: auto;
    border-left: dashed;
    border-bottom: dashed;
    border-right: dashed;
    border-top: dashed;
    border-radius: 4px;

}

#add-inp-file1 {
    display: flex;
    align-items: center;
    /* flex-direction: row; */
    justify-content: center;
    padding: 10px;
    margin-left: 123vh;
}

.add-sub-timing {
    background-color: white;
    // margin-top: 80px;
    // width: 550px;
    // background-color: whitesmoke;
    // height: 330px;
    // margin-left: 50px;
}

.add-subject-subhead {
    font-size: 12px;
    padding-top: 15px;
    padding-left: 40px;
}

.checkbox1 {
    margin-top: 10px;
    margin-left: 30px;

}

.from-1 {
    margin-left: 10px;
}

.to-1 {
    margin-left: 30px;
}

.to-container {
    margin-left: 10px;
}

.label-1 {
    margin-left: 0px;
    font-size: 15px;
    margin-top: 10px;
}

.from-select,
.to-select {
    background-color: #d3d2e6;
    ;
    margin-top: 10px;
}

.schedule-timing-box {
    padding-top: 20px !important;
    background-color: white;
    width: auto;
    height: auto;
}

.schedule-timing-container {
    margin-left: 65px;
    padding-left: 350px;
    margin-top: 50px;
}

.schedule-box {}

.add-subject-subhead-1 {
    // margin-left: 310px;
    /* margin-right: 60px; */
    // display: flex;
    // align-items: center;
    // justify-content: space-around;
}

#label-from1 {
    // font-size: 12px;
    // padding-left: 50px;
    // padding: 10px;
    // margin-left: 50px;


}

#label-from2 {
    font-size: 12px;
    padding-left: 50px;
    padding: 10px;
    margin-left: 50px;

}

#label-from3 {
    font-size: 12px;
    padding-left: 50px;
    margin-left: 20px;
    padding: 10px;

}

#label-from4 {
    font-size: 12px;
    padding-left: 50px;
    padding: 10px;
    margin-left: 40px;

}

#label-from5 {
    font-size: 12px;
    padding-left: 50px;
    padding: 10px;
    margin-left: 65px;

}

#label-from6 {
    font-size: 12px;
    padding-left: 50px;
    padding: 10px;
    margin-left: 45px;

}

#label-from7 {
    font-size: 12px;
    padding-left: 50px;
    padding: 10px;
    margin-left: 55px;

}

#label-to1 {
    font-size: 12px;
    padding-left: 50px;
    padding: 10px;
    margin-left: 50px;

}

#label-to2 {
    font-size: 12px;
    padding-left: 50px;
    padding: 10px;
    margin-left: 50px;

}

#label-to3 {
    font-size: 12px;
    padding-left: 50px;
    padding: 10px;
    margin-left: 50px;

}

#label-to4 {
    font-size: 12px;
    padding-left: 50px;
    padding: 10px;
    margin-left: 50px;

}

#label-to5 {
    font-size: 12px;
    padding-left: 50px;
    padding: 10px;
    margin-left: 50px;

}

#label-to6 {
    font-size: 12px;
    padding-left: 50px;
    padding: 10px;
    margin-left: 50px;

}

#label-to7 {
    font-size: 12px;
    padding-left: 50px;
    padding: 10px;
    margin-left: 50px;

}

#inp1 {
    width: 20px;
    outline: none;
    background-color: #d3d2e6;
    border: none;
    margin-top: 5px;
}

#inp2 {
    background-color: rgb(231, 240, 248);
    border-radius: 4px;

}

#box1 {
    border: 1px solid #5C5AB6;
    width: 50px;
}

#sub-cate1 {
    font-size: 16px;
    margin-left: 50px;
    margin-top: 40px;
}

#sub-drop1 {
    margin-left: 50px;
    // width: 200px;
    margin-top: 5px;
    height: 30px;
    float: left;
}

.plusicon-subj {
    height: 30px;
    width: 30px;
    margin-left: 20px;
}

#sub-level-head {
    font-size: 16px;
    margin-left: 50px;
    float: left;
}

#levels-subj {
    width: 200px;
    height: 30px;
    float: left;
    margin-left: 50px;
}

#sub-drop2 {
    width: 200px;
    height: 30px;
    float: left;
    margin-left: 50px;
}

#sub-cate2 {
    font-size: 16px;
    margin-left: 50px;

}

#price-head {
    font-size: 16px;
    margin-left: 50px;
}

#inp-subj-price {
    width: 200px;
    margin-left: 50px;
}

#inp-subj-price1 {
    width: 100px;
    margin-left: 50px;

}

#inp-subj-price2 {
    width: 100px;
    margin-left: 50px;

}

#inp-subj-discount {
    width: 150px;
    margin-left: 50px;
}

#inp-subj-link {
    width: 300px;
    margin-left: 50px;
}

#inp-subj-descrp {
    width: 300px;
    margin-left: 50px;
    height: 80px;
}

.teacher-time-box{
    background-color: #FFF;
}
.file-upload-teacher{
    border: 1px dashed #000

}