.view-profile{
    background-color: #5c5ab6;
    padding: 10px;
    color: white;
}
.faChevronLeft-1{
    padding: 5px;
}
.details-nav{
    padding: 5px;
    font-weight: 700;
}
.detail-img{
    width: 50%;
    border-radius: 50%;
}
.teacher-profile{
    margin-left: 200px;
    margin-top: 50px;
    flex-direction: column;
    // justify-content: center;
    box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.5)
}
.img-cont{
    align-items: center;
}
.teacher-1{
    padding: 10px;
    font-weight: 600;
}
.teacher-card{   
    background-color: #F5F6FA;
    width: 209px;
    display: flex;
    flex-direction: column;
    padding: 10px;
}
.teacher-body{
    justify-content: center;
    align-items: center;
    padding: 10px 0px 5px 10px;
}
.Quote-btn{
padding: 10px;
}
.get-quote{
    padding: 10px;
    background-color: #5c5ab6;
    color: white;
    text-transform: none;
    border: none;
    font-weight: 600;
    cursor: pointer;
}
.social-network{
    padding-top: 10px;
}
.linked-in{
    background-color: #F0F0F0;
    border: 1px solid;
    margin: 10px;
}
.icon-linked,.icon-face,.icon-twitter{
    color: #0A66C2;
}
.icon-insta{
    background: rgb(131,58,180);
    background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,63,29,1) 50%, rgba(252,176,69,1) 100%);}
.icon-youtube{
color: red;
}
.user-name{
    border: none;
    outline: none;
}
.jerry-teacher{
    padding-top: 20px;
    font-weight: 800;
    font-size: 16px;
    padding-left: 20px;
}
.jerry-1{
    padding: 0px;
    width: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 50px;
    margin-left: 30px;
    box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.5)
}
.bio-2{
    padding: 20px 20px;
}
.teacher-2{
    padding-left: 20px;
}
.jerry-head
{
    display: flex;
    flex-direction: column;
}
.p2{
    padding: 20px 70px;
    display: flex;
    align-items: center;
    font-size: 13px;
}
.subject-name{
    border-radius: 20px;
  background: #5C5AB6;
//   border: 2px solid #5C5AB6;
  padding: 5px;
  color: #FFFFFf;
  font-weight: 700;
  margin-top: 20px;
  cursor: pointer;
  border: none;
}
.align-button{
    padding-left: 30px;
}
.button-1{
    padding: 5px 20px;
    background-color: #5C5AB6;
    color: white;
    border: none;
}
.align-show{
    display: flex;
    justify-content: center;
    padding: 30px
}
.align-show{
    padding: 5px 20px;
    background-color: white;
}
.button-2{
    padding: 5px 20px;
    background-color: white;

}
.other-courses{
    padding-left: 100px;
}
.assignment-details{
    border: 1px solid black;
}
.align-submit-btn{
   
    justify-content: flex-end;
}
.button-3{
    // padding: 5px 25px;
    background-color: #5C5AB6;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 6px;
}
.Upload-Assignment{
// padding: 30px 0px;
}
.choose-file{
    border: 1px solid;
    border-radius: 7px;
    width: 180px;
    height: 30px;
    background-color: white;
}
.handle-Upload{
    // padding-left: 10px;
}
.grid-head{
    font-weight: 600;
}
.grid-content{
    font-weight: 400;
}
.file-size{

}
.button-4{
    width: 24px;
    border-radius: 50%;
    color: white;
    border: none;
 background-color: #5C5AB6;
}