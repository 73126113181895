.Main-schedule-head {
    font-size: 38px;

}

.Title-main-head {
    font-size: 28px;
    color: #263238;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.05px;
}

.subtitle-headings {
    color: #263238;

    font-family: Nunito Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.Main-schedule-boxs {
    color: black;
    float: left;
    font-size: 15px;

}

#schedule-head1 {
    font-size: 18px;

}

#schedule-category {
    width: 200px;
    height: 30px;
    border-radius: 5px;
    outline: none;
    border: none;
}

.Main-schedule-boxs2 {
    margin-left: 680px;
    margin-top: 20px;
    color: black;
    font-size: 15px;

}

#schedule-head2 {
    font-size: 18px;

}

#schedule-Topic {
    width: 200px;
    height: 30px;
    border-radius: 5px;
    outline: none;
    border: none;
}

#schedule-link {
    width: 200px;
    height: 30px;
    border-radius: 5px;
    outline: none;
    border: none;

}

.Main-schedule-boxs3 {
    margin-left: 80px;
    margin-top: 20px;
    color: black;
}

#schedule-message {
    width: 100vh;
    height: 80px;
    border-radius: 5px;
    outline: none;
    border: none;

}

.Main-schedule-boxs4 {
    margin-left: 80px;
    margin-top: 20px;
    color: black;
    float: left;
}

#schedule-date {
    width: 150px;
    height: 30px;
    border-radius: 5px;
    outline: none;
    border: none;

}

#schedule-from {
    width: 80px;
    height: 30px;
    border-radius: 5px;
    outline: none;
    margin-top: 30px;
    border: none;

}

.Main-schedule-boxs5 {
    margin-left: 15px;
    margin-top: 20px;
    color: black;
    float: left;
}

.Main-schedule-boxs6 {
    margin-left: 20px;
    margin-top: 20px;
    color: black;
    float: left;
}

.Main-schedule-boxs7 {
    margin-left: 20px;
    margin-top: 20px;
    color: black;
}

#schedule-to {
    width: 80px;
    height: 30px;
    border-radius: 5px;
    outline: none;
    margin-top: 30px;
    margin-left: 10px;

}

.btn-submit-schedule {
    background-color: #5C5AB6;
    border: 1px solid #5C5AB6;
    border-radius: 5px;
    border-width: 5px;
    color: whitesmoke;
    width: 10%;
    margin-left: 65vh;
    margin-top: 50px;
}



/* Media query for screens smaller than 768px (typical smartphones) */
@media only screen and (max-width: 767px) {
    .teacher-font-label {
        font-size: 10px;
    }

    .teacher-font-field {
        font-size: 10px;
    }
    .Title-main-head{
        font-size: 15px;
    }


}

/* Media query for screens between 768px and 991px (typical tablets) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .teacher-font-label {
        font-size: 12px;
    }
    .teacher-font-field {
        font-size: 12px;
    }
    .Title-main-head{
        font-size: 18px;
    }


}

/* Media query for screens between 992px and 1199px (larger desktops) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .teacher-font-label {
        font-size: 14px;
    }
    .teacher-font-field {
        font-size: 14px;
    }
    .Title-main-head{
        font-size: 23px;
    }

}

/* Media query for screens 1200px and larger (extra-large screens) */
@media only screen and (min-width: 1200px) {
    .teacher-font-label {
        font-size: 16px;
    }
    .teacher-font-field {
        font-size: 16px;
    }
.Title-main-head{
    font-size: 28px;
}


}