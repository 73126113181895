/* Mobile-First Approach */
.main-card {
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  border-radius: 15px;
  box-shadow: 0px 10px 20px rgba(38, 45, 118, 0.08);
  transition: 0.5s;
}

.student-font-label {
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.font-rating {
  font-size: 8px;
}

.table-view-student {
  background-color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .student-font-label {
    font-size: 13px;
  }

  .student-font-field {
    font-size: 13px;
  }

  .notifi-stu {
    font-size: 10px;
  }

  .schedule-stu {
    font-size: 10px;
  }
}

/* Media query for screens between 768px and 991px (typical tablets) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .student-font-label {
    font-size: 16px;
  }

  .student-font-field {
    font-size: 14px;
  }

  .notifi-stu {
    font-size: 12px;
  }

  .schedule-stu {
    font-size: 12px;
  }
}

/* Media query for screens between 992px and 1199px (larger desktops) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .student-font-label {
    font-size: 18px;
  }

  .student-font-field {
    font-size: 18px;
  }

  .notifi-stu {
    font-size: 14px;
  }

  .schedule-stu {
    font-size: 14px;
  }
}

/* Media query for screens 1200px and larger (extra-large screens) */
@media only screen and (min-width: 1200px) {
  .student-font-label {
    font-size: 20px;
  }

  .student-font-field {
    font-size: 20px;
  }

  .notifi-stu {
    font-size: 16px;
  }

  .schedule-stu {
    font-size: 16px;
  }
}

.Subjects-card {
  padding: 0 10px;
}

.progress-container {
  width: 100%;
  padding: 0 10px;
}

.arrow-icon {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 10px 10px 10px;
}

.progress-cent {
  font-size: 10px;
  display: flex;
  justify-content: flex-end;
  color: black;
  font-weight: 600;
  padding: 5px 0;
}

.diver {
  width: 100%;
  border-top: 1px solid #ccc;
  margin-top: 20px;
}

.maths {
  padding: 10px;
}

.icon-date,
.faClock-icon {
  color: #5c5ab6;
}

.jerry {
  font-size: 12px;
}

.img-name {
  display: flex;
}

.nofifi-date,
.class-timing {
  padding: 2px 5px;
}

.nofication-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.offered-container {
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  margin: 10px auto;
  padding: 10px;
  max-width: 400px;
  /* Example maximum width */
  border-radius: 15px;
  box-shadow: 0px 10px 20px rgba(38, 45, 118, 0.08);
  transition: 0.5s;
}

.offered-head {
  font-size: 16px;
  color: #202224;
}

.offered-cash {
  margin-top: 10px;
  color: #4880ff;
  font-weight: 600;
}

.offered-class {
  display: flex;
  padding: 10px 0 0 0;
  color: #263238;
  font-weight: 600;
}

.offered-course {
  color: #202224;
  font-weight: 700;
}

.view-offered {
  padding: 10px 0;
}

.View-btn {
  background-color: #5c5ab6;
  border-radius: 11px;
  font-weight: 700;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 6px;
  width: 70px;
}

.card-english {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  max-width: 300px;
  background-color: white;
  margin: 0 35px 10px;
  border-radius: 15px;
  box-shadow: 0px 10px 20px rgba(38, 45, 118, 0.08);
  transition: 0.5s;
}

.card-english-1 {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  max-width: 300px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 10px 20px rgba(38, 45, 118, 0.08);
  transition: 0.5s;
}

.calender {
  justify-content: space-around;
  margin: 10px auto;
  max-width: 200px;
  /* Example maximum width */
}

.active-btn {
  background-color: #00b69b;
  border-radius: 16px;
  font-weight: 700;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 8px;
  width: 70px;
}

.main-dashboard {
  font-size: 32px;
  font-weight: 700;
  color: #202224;
  padding: 17px;
}

.notifi {
  justify-content: flex-end !important;
}

.dash-head1 {
  font-weight: 700;
  color: #202224;
  padding: 8px;
}

.see-all-left {
  padding: 8px;
}

.See-all {
  margin: 10px 0 0 10px;
  font-size: 12px;
}

.see-all-noti {
  font-size: 10px;
  padding: 5px 10px 5px 5px;
}

.notifi-container {
  width: 100%;
}

.dash-card {
  // margin-top: 30px;
}

.nofication-img1 {
  width: 130px;
  height: 130px;
}

.faBookOpen {
  color: #e5da11;
  background-color: #b4bae9;
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dash-sub {
  padding: 10px;
}

.english-head {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.notification {
  font-weight: 600;
  color: black;
  display: flex;
}

.card-noti {
  display: flex;
  padding: 10px;
  width: 100%;
  width: 300px;
  max-width: 360px;
  background-color: white;
  margin: 0 auto 20px;
  border-radius: 15px;
  box-shadow: 0px 10px 20px rgba(38, 45, 118, 0.08);
  transition: 0.5s;
}

.calender-icon {
  background-color: white;
}

.row {
  display: flex;
}

.dashboard-course {
  padding: 0;
  max-width: 750px;
  margin: 0 !important;
}

.dash-img {
  max-width: 100%;
}

.offered {


  /* justify-content: center; */
}

.d-flex {
  display: flex;
}

.wd {
  width: 10px;
  color: #ffd782;
}

.dash-card {
  justify-content: center;
}

.head-english {
  font-size: 13px;
  color: #202224;
}

.view-course {
  background-color: #5c5ab6;
  color: white;
  border-radius: 10px;
  border: none;
  outline: none;
  opacity: 70%;
  font-size: 10px;
}

.english-rs {
  color: #4880ff;
  font-size: 10px;
  text-align: justify;
}

.offered-live {
  color: #263238;
  font-size: 10px;
  margin-top: 11px;
}

.dollar {
  color: #a19999;
  font-size: 10px;
}

.profile-img1 {
  width: 30px;
  border-radius: 50%;
}

.dollar1 {
  font-size: 10px;
}

.offered-dash {
  /* margin-left: 10px; */
}

.english-profile {
  /* justify-content: space-between; */
}

a {
  text-decoration: none;
  display: inline-block;
  // padding: 8px 16px;
}

// a:hover {
//   background-color: #ddd;
//   color: black;
// }

.previous {
  opacity: 73%;
  background-color: #f4f4f4;
}

.next {
  background-color: #04aa6d;
  color: white;
}

.round {
  border-radius: 50%;
}

.next-fachaveron {
  display: flex;
  align-items: center;
}

.dash-img1 {
  width: 30px;
  border-radius: 50%;
}

.dashboard-course {
  color: #202224;
  font-family: Nunito Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 13.216px;
  /* 82.601% */
}

.dash-offered-course {
  color: #202224;
  font-family: Nunito Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 9.115px;
  /* 70.114% */
}

.dash-live {
  color: #263238;
  font-family: Nunito Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 9.115px;
  /* 91.148% */
}

.dash-price {
  color: #4880FF;
  font-family: Nunito Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 9.115px;
}

.dash-actual-price {
  color: #263238;
  font-family: Nunito Sans;
  font-size: 10.875px;
  font-style: normal;
  font-weight: 600;
  line-height: 9.582px;
  /* 88.108% */
}

.dash-price1 {
  color: #A19999;
}

.card-item-min-w {
  // min-width: 100px;
  width: 250px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {  
  .card-item-min-w {
    width: 140px;
  }
}

@media screen and (min-width: 425px) and (max-width: 768px)  {
  .card-item-min-w {
    width: 95px;
  }
}
@media screen and (min-width: 320px) and (max-width: 425px)  {
  .card-item-min-w {
    width: 60px;
  }
}
.offered_head{
  font-weight: 600;
}
.offered_head1{
  font-weight: 800;
}


.admin-card-course{
height: 200px;
}