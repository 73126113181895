.batch-box1 {
    margin-left: 250px;
    padding-top: 50px;
    font-size: 15px;
    float: left;
}

.batch-drop1 {
    height: 30px;
    width: 200px;
    outline: none;
    margin-top: 10px;
}

.batch-drop_std{
    height: auto;
    width: 200px;
    outline: none;
    margin-top: 10px;
}

.batch-box2 {
    margin-left: 100vh;
    padding-top: 50px;
    font-size: 15px;

}

.batch-drop2 {
    height: 30px;
    width: 200px;
    outline: none;
    margin-top: 10px;
}


#create-submit-btn{
    background-color: #5C5AB6;
    border: 1px solid #5C5AB6;
    border-radius: 5px;
    border-width: 5px;
    color: whitesmoke;
    width: 15%;
    margin-top: 25px;
    margin-left: 67vh;
}