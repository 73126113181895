.profile-card {
  border-radius: 4px;
  background-color: #f5f6fa !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  border: none !important;
}

.stu-profile-req {
  color: #fff;
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.21px;
  background-color: #5c5ab6;
  border: none;
}

.profile-main-head {
  background-color: #5c5ab6 !important;
  color: white !important;
}

.profile-field {
  border-radius: 3px !important;
  border: 1px solid #263238 !important;
}

.social-media-icon:hover {
  cursor: pointer;
}