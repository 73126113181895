.total{
  // height: 60px;
  border: 1px solid black;
}
.balance{
  // height: 60px;
  width: 150px;
  border: 1px solid black;
  background-color: #F3F3F3;
  color: #263238;
}