.Main-head-test{
    font-size: 22px;
    color: black;
    margin-top: 20px;
    margin-left: 50px;
    
}
.main-div-drop{
    float: left;
    margin-left: 50px;

}
.test-drop-head{
    font-size: 18px;
    margin-left: 50px;
}
.test-drop-box{
    font-size: 12px;
    margin-top: 20px;
    margin-left: 50px;
    width: 200px;
    border-radius: 5px;

}
.main-div-drop1{
    margin-left: 500px;
}
.test-drop-box1{
    font-size: 12px;
   
    margin-left: 50px;
    width: 200px;
    border-radius: 5px;
}
.main-grid{
    padding-left: 80px;
}
#boder{
    border: none;
    outline: none;
}