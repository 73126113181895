.Main-Attendence-boxs {
    margin-left: 70px;
    margin-top: 20px;
    color: black;
    float: left;
}

.Main-Attendence-boxs1 {
    margin-left: 70px;
    margin-top: 20px;
    color: black;
}

.Main-Attendenceabsent-boxs {
    margin-left: 680px;
    margin-top: 20px;
    color: black;
}

#attendence-present {
    color: green;
    width: 200px;
    height: 30px;
    border-radius: 5px;
    outline: none ;

}

#attendence-absent {
    color: red;
    width: 200px;
    height: 30px;
    border-radius: 5px;
    outline: none ;

}