.freelancer-nav {
  background-color: rgb(114, 76, 150);
  height: 2.6rem;
}

.body {
  width: 800px;
  height: 500px;
  background-color: whitesmoke;
  margin-left: 350px;
  margin-top: 30px;
}

label {
  display: inline-block;
  margin-bottom: 5px;
}

.form-row {
  display: flex;
}

.form-group {
  padding: 5px;
  
}

/* .form-group input {
  width: 50%;
} */

.text-area {
  width: 75%;
}

.freelancer-btn {
  background-color: rgb(189, 95, 189);
  color: white;
  padding: 5px;
  margin-left: 20rem;
}

  