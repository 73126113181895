.subject-Teacher {
  font-weight: 700;
  border-radius: 5px;
  // margin-left: 50px;
  // margin-top: 50px;
  color: #263238;
  padding: 7px 15px;
}

.btn1-teacher-subj {
  // background-color: #5C5AB6;
  color: white;
  border: none;
  padding: 2px;
  cursor: pointer;
  font-size: 12px;
  align-items: center;
  border-radius: 4px;
  height: 0px;

}

.subject-Teacher {
  padding: 7px 15px;
  background-color: #EAE9FB;
  border: 1px solid #263238;

  &.active {
    .teacher-subject {
      color: #5C5AB6 ;
      font-weight: 700;
    }

    border: 2px solid #5C5AB6;
    color: #263238 ;

    .btn1-teacher-subj {
      color: #263238 !important;
      font-family: Nunito Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      // margin-top: 30px;
    }
  }
}

.coupon-Teacher {
  padding: 7px 15px;
  background-color: #EAE9FB;
  border: 1px solid #263238;
  color: #263238 ;

  &.active {
    .teacher-coupon {
      color: #5C5AB6 ;
      font-weight: 700;
    }

    border: 2px solid #5C5AB6;
    

    .btn2-teacher-subj {
   
      background-color: #5C5AB6;
      color: #263238 !important;
      font-family: Nunito Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}


// .btn1-teacher-subj {
//     padding: 7px 15px;
//     
//     color: #5c5ab6;
//     background-color: #EAE9FB;
//     border: 2px solid #5C5AB6;
//     border-radius: 5px;
//     // border-width: 5px;
//     color:  #5C5AB6;
//     // width: 10%;
//     
//   
//     float: left;
//     // opacity: 0.7;
//     transition: 0.3s;
// }

// .btn2-teacher-subj {
//     background-color: #EAE9FB;
//     border: 1px solid #5C5AB6;
//     border-radius: 5px;
//     // border-width: 5px;
//     color: #252525;
//     width: 10%;
//     margin-left: 10px;
//     margin-top: 50px;
//     opacity: 0.7;
//     transition: 0.3s;
// }

// .btn1-teacher-subj:hover {
//     opacity: 1;
//     transition: ease-in;
//     animation: forwards;
// }

// .btn2-teacher-subj:hover {
//     opacity: 1;
//     transition: ease-in;
//     animation: forwards;
// }
.btn-addsub-teacher {
  border-radius: 5px;
  padding: 5px 25px;
  border: none;
  background-color: #5c5cb6;
  color: white;
  cursor: pointer;
}

.subject-container {
  margin-top: 20px;
  margin-left: 50px;
  margin-bottom: 20px;
}

.buttons {
  padding: 10px 30px;
  margin-left: 19px;
}

.action-bar {}

.filter_coupon {
  float: left;
  margin-left: 20px;
  font-size: 15px;
  padding-left: 30px;
}

.coupon_select {
  width: 200px;
  margin-bottom: 20px;
  height: 30px;
  outline: none;
}

.labe_coupon {
  font-size: 18px;
}

.filter_btn {
  background-color: #5C5AB6;
  width: 15%;
  border-radius: 5px;
  border: 1px #5C5AB6;
  margin-top: 30px;
  margin-left: 50px;
  color: whitesmoke;
  height: 40px;
}
.ul-menu{
  list-style-type: none;
}