body {
    font-family: 'Muli', sans-serif;
    background: #ddd;
}

.bottom {
    color: white;
    font-family: sans-serif;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: black;
    flex-direction: row;
    justify-content: center;
    font-size: 18px;
    padding: 18px;
}

//Head
.head_description {
    // background-image: url("../images/img-1.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: calc(100% - 88px);
    position: relative;
    overflow: hidden;
    background-position: 0px 0px;

    .w-100 {
        width: 100%;
    }
}

.what-learn {
    width: 280px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    border: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    height: 40px;
    padding: 10px;

    &:focus-visible {
        outline: none;
    }
}

.search-container {
    color: black;
    display: flex;
    justify-content: flex-end;
}

.search-button {
    font-family: sans-serif;
    height: 40px;
    border: none;
    border-radius: 4px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    width: 35px;
    background: linear-gradient(180deg, #8280FF 0%, #3936CC 100%);
    color: white;
}

.tution_button {
    flex-direction: row-reverse;
    position: absolute;
    top: 20pc;
    left: 2pc;

}

.btn1 {
    font-family: sans-serif;
    cursor: pointer;
    background-color: #252525;
    color: white;
    padding: 5px 14px;
    border-radius: 5px;
    border: none;
    // background: #252525;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    width: 110px;
}

.tution_p {

    font-family: sans-serif;
    color: white;
    font-weight: 700;

    // font-family: Nunito Sans;
    // font-size: 15px;
    // font-style: normal;
    font-weight: 700;
    // line-height: normal;
    letter-spacing: 0.84px;
    color: #FFFFFF;
}

.tutor_button {
    flex-direction: row-reverse;
    position: absolute;
    top: 23pc;
    left: 2pc;
}

.btn2 {
    font-family: sans-serif;
    cursor: pointer;
    background-color: #252525;
    color: white;
    padding: 5px 14px;
    border-radius: 5px;
    border: none;
}

.tutor_p {
    font-family: sans-serif;
    color: white;
    font-weight: 700;
}

.course_button {
    flex-Direction: row-reverse;
}

.btn3 {
    // width: 100px;
    border-radius: 5px;
    font-family: sans-serif;
    cursor: pointer;
    background-Color: #252525;
    color: white;
    padding: 5px 14px;
    border: none;
}

.btn-4 {
    border-radius: 5px;
    font-family: sans-serif;
    cursor: pointer;
    background-Color: #252525;
    color: white;
    border: none;
}

.course_p {
    font-family: sans-serif;
    color: white;
    font-weight: 700;
}

// .freelance_button {
//     flex-Direction: row-reverse;
//     position: absolute;
//     top: 29pc;
//     left: 2pc;
// }

.btn4 {
    border-radius: 5px;
    font-family: sans-serif;
    font-size: 10px;
    cursor: pointer;
    background-color: #252525;
    color: white;
    padding: 5px 14px;
    margin: 10px;
    border: none;

}

.freelance {
    font-size: 1.1vw;

    p {
        margin-bottom: 2px;
    }
}

.freelance_p {
    font-Family: sans-serif;
    color: white;
    font-weight: 700;
}

.freelance_p1 {
    font-Family: sans-serif;
    color: white;
    font-weight: 400;
}

.freelance_p2 {
    font-Family: sans-serif;
    color: white;
    font-weight: 400;
}

.demo-button {
    width: 80%;
    background: rgba(255, 255, 255, 0.25) !important;
    color: white !important;
    border: none;
    box-shadow: 0px 0px 4.75px rgba(0, 0, 0, 0.1);
    ;
    border-radius: 48px !important;
    font-weight: 700 !important;


}


.landing_col_absolute {
    position: absolute;
    left: 100%;
}

.landing_col_absolute_search {
    position: absolute;
    left: 80%;
}


.user-icon {
    width: 20px;
    height: 20px;
    padding: 4px;
    margin-right: 10px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    border: none;
}

.bottom-btn {
    margin-left: 10px;
    padding: 2px;
    font-weight: 600;
    border: none;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 7px 13px;
    align-items: center;
}

.captions {
    // position: absolute;
    // top: 40vh;
    font-size: 1vw;
    width: 650px;
    padding-left: 25px;

    button {
        font-size: 10px;
    }
}

.center-div-row {
    height: 100%;
    align-items: center;
}

.left-side {
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 30px;
}

.row-left {
    display: flex;
    align-items: center;
}

.ml-15 {
    margin-left: 15px;
}

@media screen and (min-height: 950px) {
    .head_description {
        background-size: cover !important;
        background-position: 0px -80px;
    }
}
@media screen and (min-width: 1200px) {
    .landing-home-txt {
      
      font-size: 18px;
    }
  .happy_clients{
    font-size: 20px;
  }
    /* Add more styles as needed for extra-large screens */
  }