.btn-create-Notification {
    // background-color: #5C5AB6;
    border: 1px solid #000000;
    border-radius: 5px;
    color: rgb(0, 0, 0);
    width: 15%;
    margin-left: 0px;
    margin-top: 50px;
    height: 30px;
    transition: width 2s;
}

.btn-create-Notification1 {
    // background-color: #5C5AB6;
    border: 1px solid #000000;
    border-radius: 5px;
    color: rgb(8, 8, 8);
    width: 15%;
    margin-left: 50px;
    margin-top: 50px;
    height: 30px;
}

.btn-create-Notification2 {
    background-color: #5C5AB6;
    border: 1px solid #5C5AB6;
    border-radius: 5px;
    color: rgb(247, 247, 247);
    width: 15%;
    margin-left: 0px;
    margin-top: 50px;
    height: 30px;
    margin-left: 50px;
}

/* Add this to your CSS file */
.active1 {
    border: 3px solid #5C5AB6;
    /* Change the background color to your preferred active color */
    color: #5C5AB6;
    /* Change the text color to your preferred active color */
    /* Add any other styles you want for the active button */

}

.date-cate {
    margin-left: 50px;
    border-radius: 5px;
    height: 30px;
    width: 200px;
    margin-top: 50px;
}

.notifi-body {
    height: 100%;
    width: auto;
    margin-bottom: 150px;
}

.notification-para1 {
    text-align: center;
    padding-top: 30px;
    font-size: 15px;
}

.profile-pic {
    float: left;
    height: 100px;
    width: 100px;
    padding-top: 30px;
    margin-left: 100px;
    border-radius: 5px;
}