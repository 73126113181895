.DashBoard {
    background-color: white;
    margin-left: 20px;
    margin-top: 20px;
    width: auto;
    height: auto;
}

.Group-icon {
    height: 40px;
    width: 40px;
    display: flex;
    margin-left: 50px;
}

.mdbcard-title {
    font-size: 22px;

}

.mdbcard-head {
    display: flex;
}

.mdbcard-title-1 {
    float: right;
    margin-left: 0px;
    color: green;
}

.mdbcard-title-2 {
    float: right;
    margin-left: 0px;
    color: red;
}

.sale-graph {
    background-color: white;
    margin-left: 20px;
    margin-top: 20px;
    width: auto;
    height: 300px;

}

.mdbcard-head-1 {
    padding-top: 0px;
}

.calender-teacher {
    font-size: 15px;
    background-color: whitesmoke;
    border-collapse: collapse;
}

.grid-container {
    padding-top: 20px;
    padding-left: 20px;
}

.dashboard-table {
    font-size: 15px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: rgb(0, 0, 0);
    width: auto;
    text-align: center;
    padding-top: 10px;
    height: auto;
    width: auto;
}

.dashboard-table2 {
    font-size: 15px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: white;
    width: auto;
    text-align: center;
    padding-top: 10px;
    padding: 10px 10px;
}

.main-teacher-table {
    background-color: white;
    border-radius: 5px;
}

.Group-icon-2 {
    height: 30px;
    width: 30px;
    display: flex;
    margin-left: 50px;
    color: black;
}

.mdbcard-title-3 {
    float: right;
    margin-left: 0px;
    color: rgb(0, 0, 0);
}

.btn-dash-teacher {
    border-radius: 5px;
}
.card{
    margin-left: 10px;
}

.teacher-course-back{
    background-color: #fff;
    height:290px;
    border-radius: 5px;
}