.quotation-details-table {
  border: 1px solid;
  padding: 40px 18px;

  .assignment-details {
    background-color: #FAFAFA;
  }
}

.quote-status {
  // justify-content: flex-end;
}

.save-btn {
  padding: 5px 20px;
  justify-content: center;
}

.save-btn-quote {
  background-color: #5C5AB6;
  padding: 5px 30px;
  color: white;
  border: none;
  font-weight: 500;
  cursor: pointer;
}

.data-table1 {
  padding: 0px;
}

.dwnld-icon {
  color: black;
}

.input-pdf {
  border: none;
  background-color: #f9f9fc;
}

.quote-content1 {
  color: rgb(72, 190, 72);
}

.red-text {
  color: red;
}

.green-text {
  color: green;
}

.yellow-text {
  color: yellow;
}

.active-row {
  font-weight: 800;
  background-color: white;
  color: green;
  /* Change the text color to make it visible on the green background */
}

.inactive-row {
  color: red;
  background-color: white;
  /* Change the text color to make it visible on the red background */
}

.pending {
  color: #128807;
}

.complete {
  color: rgb(227, 227, 17);
}

.job-details-head {
  color: #263238;
  font-family: Nunito Sans;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.75px;
}