.main-div-error{
    align-items: center;
    text-align: center;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  .error-content{
    font-size: 200px;
    color: #221359;
    font-weight: bolder;
  }
  .error-content1{
    font-size: 20px;
    color: #5c5ab6;
    font-weight:lighter;
  }