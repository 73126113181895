.who-you {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 800;
  font-size: 34px;
  line-height: 46px;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.04em;
  background-color: white;
  margin-bottom: 50px;
}

.who_to_login_as_container {
  background-color: white;
}

.choose-card {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px;
}

.card-head {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: #5B72EE;
  margin-bottom: 25px;
}

.card-head1 {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;

  text-align: center;
  color: #00CBB8;
}

.card-p {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  color: #41494E;
}

.note-icon {
  align-items: center;
  background-color: #5B72EE;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  padding: 15px;
  box-shadow: 0px 10px 40px rgba(54, 61, 136, 0.06);
  position: absolute;
  top: -14px;
}

.note-icon-schedule {
  align-items: center;
  background-color: #00CBB8;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  padding: 15px;
  box-shadow: 0px 10px 40px rgba(54, 61, 136, 0.06);
  position: absolute;
  top: -14px;
}


// .calender-icon{
//   align-items: center;
//   display: flex;
//   border-radius: 50%;
//   width: 50px;
//   height: 50px;
//   padding: 17px;
//   background: #00CBB8;
//   box-shadow: 0px 16px 40px rgba(37, 44, 113, 0.1);
// }
// section {
//   width: 100%;
//   height: 100vh;
//   box-sizing: border-box;
//   padding: 140px 0; 
// }


.view-details-btn{
  outline: none !important;
  // border: none !important;
}

.view-all {
  font-weight: 600;
  color: #252525;

  a {
    color: #252525;
  }
}

.card {
  position: relative;
  border-radius: 15px;
  padding: 40px 20px;
  box-shadow: 0px 10px 60px rgba(38, 45, 118, 0.08);
  transition: .5s;
  // align-items: center;
  display: flex;
  justify-content: center;
  width: auto;

  &.left-card {
    margin-right: 50px
  }

  &.right-card {
    margin-left: 50px;

    .card-head {
      color: #00CBB8;
    }

    .note-icon {
      background-color: #00CBB8;
    }
  }
}

.card:hover {
  transform: scale(1.1);
}

.featured-view {
  justify-content: space-between;
}

.featured-head {
  font-weight: 700;
  font-size: 30px;
  line-height: 55px;
  letter-spacing: 0.005em;
  color: #252525;
}

.feature {
  width: 160px;
  height: 270px;
  margin-left: 15pc;
  margin-top: 2pc;
}

.feature-c {
  display: flex;
  justify-content: center;
  margin-right: 20px;
  padding-top: 1pc;

}

.course1-img {
  width: 200px;
  height: 130px;
}

.viewdeatils {
  margin-bottom: 15px;
}

.course-head {
  margin-top: 10px;
  font-size: 13px;
  color: #252525;
  font-family: Nunito Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.1px;
}

.detail-btn {
  border-radius: 20px;
  background: #FFFFFF;
  border: 2px solid #5C5AB6;
  padding: 5px;
  color: #5C5AB6;
  font-weight: 700;
  margin-top: 20px;
  cursor: pointer;
}
.duration {
  margin-top: 10px;
  font-size: 13px;
  font-weight: 600;
}

.student {
  font-weight: 600;
  font-size: 13px;
  margin-top: 3px;
}

.FaArrowRight {
  color: #252525;
  margin-left: 10px;
}

.all-featured {
  margin-top: 33px;
  padding: 0 10%;
  flex-wrap: wrap;
  justify-content: center;

  .card {
    width: 225px;
  }

  .feature-c {
    box-shadow: 0px -1px 21px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
}