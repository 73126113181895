.coupon-main-head {
    padding-left: 50px;
    margin-top: 30px;
    font-size: 22px;
    color: black;
}

.MainDiv-coupon {
    height: 100%;
    width: 100%;
  
    background-color: whitesmoke;

}

.para1-coupon-div {
    padding-left: 150px;
    padding-top: 50px;
    font-size: 18px;
}

#label-coupon-cat {
    font-size: 15px;
}

#coupon-1 {
  
    margin-top: 30px;
}

#course-1 {
   
    margin-top: 30px;
}

#course-2 {
    
    margin-top: 30px;
}

#discount-1 {
    margin-left: 180px;
    margin-top: 30px;
}

#discount-2 {
   
    margin-top: 30px;
}

#label-coupon {
    font-size: 15px;
}

.div-cate-coup {
    float: left;
    margin-left: 180px;
    margin-top: 15px;
}

#coupon-11 {
    border: 1px solid rgb(0, 0, 0);
    outline: none;
    width: 19px;
    height: 19px;
    border-radius: 3px;
}

#coupon-22 {
    border: 1px solid black;
    outline: none;
    width: 200px;
    height: 25px;
    border-radius: 3px;
}

.label-sub-coup {
    margin-left: 480px;
    margin-top: 20px;
}

#coupon-2 {
    margin-top: 30px;
}

.hr-coupon {
    width: 120vh;
    margin-left: 150px;
}

.under-line-coupon {
    color: black;
    width: 150vh;
    margin-top: 50px;
    margin-left: 150px;
    background-color: black;
}

#course-coupon {
    width: 200px;
    height: 30px;
    margin-left: 180px;
    border: 1px solid black;
    outline: none;
}

#label-coupon2 {
    margin-left: 180px;
    font-size: 15px;
    margin-top: 30px;

}

.box-coupon {
    margin-top: 50px;
    margin-left: 180px;
    font-size: 15px;
}

.box-coupon-head {
    margin-top: 50px;
    margin-left: 570px;
    font-size: 15px;
}

#box-coupon1 {
    margin-left: 180px;
    font-size: 15px;
    margin-top: 0px;
    width: 200px;
    border-radius: 5px;
    border: 1px solid black;
    outline: none;
}

.box-coupones {
    float: left;
}

#box-coupon_date {
    margin-left: 50px;
    font-size: 15px;

    margin-top: 50px;
    width: 200px;
    border-radius: 5px;
    border: 1px solid black;
    outline: none;
    height: 30px;
}

.box-coupones-date {
    display: flex;
    height: 20px;
}

.btn1-teacher-coupon {
    background-color: #5C5AB6;
    border: 1px solid #5C5AB6;
    border-radius: 5px;
    border-width: 5px;
    color: whitesmoke;
    width: 10%;
    margin-left: 80vh;
    margin-top: 50px;
}

.error-alert {
    background-color: #f44336;
    /* Red background color */
    color: white;
    /* White text color */
    padding: 10px;
    /* Add some padding */
    margin-bottom: 10px;
    /* Add some margin to separate it from other elements */
}

/* Add these styles to your stylesheet */
.active-status {
    color: green;
    /* Green text for active status */
}

.inactive-status {
    color: red;
    /* Red text for inactive status */
}

.title-head-coupon {
    color: #252525;
    font-family: Nunito Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

@media screen and (min-width: 1401px) and (max-width: 2001px) {
    .title-head-coupon{
        font-size: 24px;
    }
}
@media screen and (min-width: 1024px) and (max-width: 1400px) {
    .title-head-coupon{
        font-size: 24px;
    }
}

@media screen and (min-width: 991px) and (max-width: 1023px) {
    .title-head-coupon{
        font-size: 20px;
    }
}

@media screen and (min-width: 500px) and (max-width: 990px) {
    .title-head-coupon{
        font-size: 16px;
    }
}

@media screen and (min-width: 100px) and (max-width: 499px) {
    .title-head-coupon{
        font-size: 10px;
    }
}