.quote-stu,
.request-quote {
  // margin-left: 40px;
  background-color: #5C5AB6;
  color: white;
  border: none;
  padding: 5px;
  font-weight: 700;
  display: flex;
  align-items: center;
  // border-radius: 5px;
}

.icon-names {
  color: white;
  background-color: #5C5AB6;
  height: 43px;
  // border-top-left-radius: 10px;
  // border-top-right-radius: 10px;
}

.online-tuition {
  padding-top: 30px;
  flex-wrap: nowrap;
  justify-content: center;
}

.quatation {
  background-color: #EAE9FB;
}

.Schedule-container {
  justify-content: center;
}

.schedule-input {
  justify-content: center;
}

.schedule-subhead {
  font-weight: 600;
}

.schedule-timing {
  padding: 10px;
  background-color: white;
}

.request-quote {
  font-weight: 600;
  padding: 5px 10px;
}

.request-btn {
  padding: 10px;
  justify-content: flex-end;
}

.currency {
  flex-direction: column;
  margin-left: 10px;
}

.price-2,
.price-p1 {
  margin-left: 20px;
}

.input-2,
.input-3 {
  width: 500px;
  height: 100px;
}

.container-grid {
  margin-left: 200px;
}

.online-main {
  padding: 20px;
  justify-content: center;
}

.preffed-schedule {
  margin-top: 20px;
  width: 600px;
}

.freelance-container {
  justify-content: space-around;
}

.time-box {
  height: 30px;
  width: 60px;
  border: none;
  background-color: #EAE9FB;
  font-size: 9px;
}

.time-input {
  color: #000;
  font-family: Nunito Sans;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 98.9%;
  /* 12.542px */
}

.student-qua-head {
  color: #263238;
  font-family: Nunito Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.free-quote {
  background-color: #EAE9FB;
  border-radius: 6px;
  border-color: #5c5ab6;
  color: #5c5ab6;
  border-width: 3px;
}

.student-quote-head {
  color: #263238;
  font-family: Nunito Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.stu-free-quote {
  color: #263238;
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.stu-file-quote {
  color: #263238;
  font-family: Nunito Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.stud-online-time {
  color: #263238;
  font-family: Nunito Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.742px;
}

.stud-online-day {
  text-transform: capitalize;
  color: #263238;
  font-family: Nunito Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.94px;
}

.stud-online-from {
  color: #000;
  font-family: Nunito Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.71px;
}
.button-3{
  color: #FFF;
  font-family: Nunito Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background-color: #5C5AB6;
}
.stu-free-date {
  border-radius: 5px;
  border: 1px solid #455A;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #5C5AB6; 
  border-width: 2px; 
  background-color: #5C5AB6;
}




/* Media query for screens smaller than 768px (typical smartphones) */
@media only screen and (max-width: 767px) {
  .quote-head {
      font-size: 10px;
  }
  

}
/* Media query for screens smaller than 768px (typical smartphones) */
@media only screen and (max-width: 767px) {
  .quote-head {
      font-size: 12px;
  }
  .quote-content{
    font-size: 12px;
  }

}

/* Media query for screens between 768px and 991px (typical tablets) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .quote-head {
      font-size: 14px;
  }
  .quote-content{
    font-size: 14px;
  }
}

/* Media query for screens between 992px and 1199px (larger desktops) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .quote-head {
      font-size: 14px;
  }
  .quote-content{
    font-size: 14px;
  }
}

/* Media query for screens 1200px and larger (extra-large screens) */
@media only screen and (min-width: 1200px) {
  .quote-head {
      font-size: 14px;
  }

}