.Quest-div-box {
    margin-top: 10px;
    padding-top: 1px;
    margin-left: 100px;
    width: 300px;
    height: auto;
    border: 1px solid black;
    padding-left: 30px;
}

.sub-head-test {
    margin-top: 50px;
    padding-top: 15px;
    margin-left: 100px;
    font-size: 15px;
}

.dropdown1 {
    padding-left: 10px;
    border: 1px solid black;
    // width: 800px;
    // margin-left: 100px;
    height: auto;
    background-color: whitesmoke;
    margin-top: 10px;
}

.main-btn {
    font-size: 20px;
    margin-top: 10px;
    border-radius: 5px;
    background-color: whitesmoke;
    height: 30px;
    width: 100%;
    border: none;
    outline: none;
}

.dropdown-content {
    margin-left: 200px;
    width: auto;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    margin-top: 10px
}



.sub-btn {
    font-size: 18px;
    margin-top: 10px;
    border-radius: 5px;
    background-color: #c0bdbc;
    height: 30px;
    border: none;
    outline: none;
    width: 200px;
}


.sub-btn-v {
    font-size: 18px;
    border-radius: 5px;
    background-color: #c0bdbc;
    height: 30px;
    border: none;
    outline: none;
}



.para1-test {
    padding-top: 30px;
    font-size: 15px;
}

.para1-test1 {
    font-size: 15px;
    border: 1px solid black;
    height: auto;
    margin-top: 10px;
    padding-left: 0px;
}
#question-para{
    width: 500px;
    outline: none;

}

.inp-quest {
    width: 490px;
    outline: none;
    border: none;
    height: 80px;
    background-color: whitesmoke;
}

.options-test {
    float: left;
    font-size: 12px;
    padding-top: 5px;
}

.option-inp-test {
    font-size: 14px;
    margin-left: 20px;
    border: 1px solid black;
    width: 300px;
    padding-left: 20px;
    // height: 40px;
    border-radius: 5px;
    padding-top: 5px;
}
.option-inp-test-v {
    font-size: 14px;
    margin-left: 20px;
    border: 1px solid black;
    padding-left: 20px;
    // height: 40px;
    border-radius: 5px;
    padding-top: 5px;
}

.option-inp-test2 {
    font-size: 14px;
    border: 1px solid black;
    // height: 40px;
    border-radius: 5px;
}


.test-file-inp {
    border: 1px solid black;
}

.Quest-test-btn {
    font-size: 15px;
    margin-top: 30px;
    border-radius: 5px;
    // background-color: #5C5AB6;
    height: 30px;
    border: none;
    outline: none;
    width: 150px;
    margin-left: 0px;
    border: 1px solid #5C5AB6;
}

.cancel-test-btn {
    font-size: 15px;
    margin-top: 30px;
    border-radius: 5px;
    // background-color: #5C5AB6;
    height: 30px;
    border: none;
    outline: none;
    width: 100px;
    margin-left: 25vh;
    border: 1px solid #5C5AB6;
}


.cancel-test-btn-v {
    font-size: 15px;
    margin-top: 30px;
    border-radius: 5px;
    // background-color: #5C5AB6;
    height: 30px;
    border: none;
    outline: none;
    width: 100px;
    border: 1px solid #5C5AB6;
}


.submit-test-btn {
    font-size: 15px;
    margin-top: 30px;
    border-radius: 5px;
    background-color: #5C5AB6;
    height: 30px;
    border: none;
    outline: none;
    width: 100px;
    margin-left: 20px;
}

.checkbox-input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    background-color: whitesmoke;
    /* Add some margin to separate from other content */
}

.checkbox-label {
    float: left;
    align-items: center;
    margin-bottom: 5px;
    background-color: whitesmoke;
    margin-left: 10px;
    padding-top: 5px;
    
}

.text-input {
    padding: 5px;
    width: auto;
    border-radius: 4px;
    font-size: 14px;
    outline: none;
    border: none;
    background-color: whitesmoke;
    bottom: 50px
}