.App {
  text-align: center;
}

.dropdown {
  cursor: pointer;
}

.admin-fixed-navbar {
  position: fixed;
  width: 170vh;
  z-index: 1000;
  background-color: #fff;
  overflow: auto;
}


.lading-navbar {

  background-color: #8280FF !important;

}

.eye-icon {
  width: 20px;
  height: 20px;
}

.admin-head-dash {
  color: #202224;
  font-family: Nunito Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.114px;
}


.teacher-course-detail-head {
  color: #000;
  font-family: Nunito Sans;
  font-size: 23.976px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}


.admin-search-icon-bg {
  background-color: #fff;
  border-radius: 19px 0px 0px 19px;
}

.admin_toggle {
  cursor: pointer;
}

.dropdown-item {
  display: block;
  padding: 8px 16px;
  text-decoration: none;
  color: #333;
}

.admin-dropdown {
  border-radius: 5px;
  border: 1px solid #455A64;
}

.admin-dropdown_date {
  border-radius: 5px;
  border: 1px solid #455A64;

}

.adimin_date {
  border-radius: 5px;
  border: 1px solid #455A64;
  height: 40px;
}

.Category_dataTable {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  /* Adjust as needed */
}


.App-logo {
  height: 40;
  width: 50vh;
  pointer-events: none;
}

.bootBtn {
  border-radius: 4px;
  background: #5C5AB6;

}


.bootBtn:hover {
  border-radius: 4px;
  background: #5C5AB6;
}

.bootBtn-section {
  border-radius: 0px;
  background: #5C5AB6;
}


.bootBtn-section:hover {
  background: #5C5AB6;
}

.adminCardBGW {
  border-radius: 3px;
  border: 0.8px solid #000;
  background: #F3F3F3;
}


.student-dashboard-head {
  color: #202224;
  font-family: Nunito Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.114px;
}

.student-card-tittle {
  color: #5b6063;
  font-family: Nunito Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.student-first-card {
  background-color: #8280FF;
}

.student-loading-color {
  color: #8280FF;
}

.adminCardBGR {
  border-radius: 3px;
  border: 0.8px solid #000;
  background: #5C5AB6;
}

.adminSubHead {
  color: #000;
  font-family: Nunito Sans;
}

.admin-sub-head {
  color: #000;
  font-family: Nunito Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.6px;
}

.admin-sub-head-card {
  color: #000;
  font-family: Nunito Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.6px;
}

.adminSubCard {
  border: 1px solid #000;
  background: #FAFAFA;
  border-radius: 0%;
}

.adminOPbtn {
  border-radius: 6px;
  border: 3px solid #5C5AB6 !important;
  color: #5C5AB6;
}

.adminOPbtn:hover {
  border-radius: 6px;
  border: 3px solid #5C5AB6 !important;
  color: #5C5AB6;
}

.adminPdfIcon {
  color: red;
}

.adminEyeIcon {
  color: #5C5AB6;
}

.outline-btn-admin {
  background: #FFFFFF;
  border: 2px solid #5C5AB6;
  color: #5C5AB6;
  cursor: pointer;
}

.outline-btn-admin:hover {
  background: #FFFFFF;
  border: 2px solid #5C5AB6;
  color: #5C5AB6;
  cursor: pointer;
}

.admin-video-preview-card {
  border-radius: 6px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}


.admin-card {
  position: relative;
  border-radius: 14px;
  background: #FFF;
  box-shadow: 6px 6px 54px 0px rgba(0, 0, 0, 0.05);
  transition: .5s;
  align-items: center;
}


.student-card {
  position: relative;
  border-radius: 14px;
  box-shadow: 6px 6px 54px 0px rgba(0, 0, 0, 0.05);
  transition: .5s;
  align-items: center;
}

.landing-card {
  position: relative;
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 10px 60px 0px rgba(38, 45, 118, 0.08);
  transition: .5s;
  align-items: center;
}



.chat-card {
  position: relative;
  background: #FFF;
  box-shadow: 6px 6px 54px 0px rgba(0, 0, 0, 0.05);
  transition: .5s;
  align-items: center;
}

.chat-card-search {
  border-radius: 9.15px;
  background: #F5F5F5;
}


.chat-card-head {
  background: #5C5AB6;
}

.chat-emoji-span {
  background: #FFF;
  border-color: #fff;
}

.chat-border-clr-wht {
  border-color: #fff;

}

.chat-send-icn {

  background: #8280FF;
  color: #fff;
}


.chat-int-card {
  background: #EEE;
}

.chat-search {
  border-radius: 6.863px;
  background: #FFF;
}

.chat-list-user-card {
  background: #F5F5F5;
}

.chat-time-txt {
  color: #000;
  font-family: Inter;
  font-size: 9.15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.201px;
}

.chat-unread-txt {
  color: #fcfcfc;
  font-family: Inter;
  font-size: 17.15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.201px;
  background-color: #00B69B;
  border-radius: 50%;
}




.chat-msg-name {
  color: #09A0D0;
  font-weight: 600;
  font-size: 12px;
}

.chat-list-stu-name {
  color: #262626;
  font-family: Inter;
  font-size: 14.869px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.327px;
}

.chat-list-img {
  border-radius: 18.301px;

}

.chat-card-tittle {
  color: #FFF;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.44px;
}

.chat-list {
  background: #EEE;
}

.student-card-icon-color {
  color: #5C5AB6;
}

.student-card-loading {
  position: relative;
  border-radius: 14px;
  background: #bcbbfa;
  box-shadow: 6px 6px 54px 0px rgba(0, 0, 0, 0.05);
  transition: .5s;
  align-items: center;
  z-index: -5;
}

.admin-card-eye {
  position: relative;
  transition: .5s;
  width: 30px;
  align-items: center;
  border-radius: 3.692px;
  border: 1.846px solid #5C5AB6;
  background: #FFF;
}

.upload-course-card {
  border-radius: 4px;
  background: #263238;
  box-shadow: 6px 6px 54px 0px rgba(0, 0, 0, 0.05);
  transition: .5s;
}


.admin-card-section {
  position: relative;
  border: 1px solid #000;
  background: #F9F9F9;
  box-shadow: 6px 6px 54px 0px rgba(0, 0, 0, 0.05);
  transition: .5s;
  align-items: center;
}


.admin-view-image {
  width: 100%;
}

.chat-top-img {
  max-width: 41px;
  max-height: 41px;
}

.chat-top-act-txt {
  color: #FFF;
  font-size: 13.726px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.302px;
}

.admin-input-view {
  background: #EFEEEE;
}

.admin-input {
  border-radius: 4.742px;
  border: 0.948px solid #263238;
  background: #FAFAFA;
}

.admin-label {
  color: #263238;
  font-family: Nunito Sans;
  font-size: 18.893px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}



.custom-green {
  border-color: green;
}

.custom-red {
  border-color: red;
}

.admin-video-personCard {
  border-radius: 6px;
  border: 1px solid #000;
  background: #FAFAFA;
}

.admin-gray-text {
  color: #202224;
  font-family: Nunito Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 0.7;
}

.admin-green-dash {
  color: #00B69B;
  font-size: 15px;

}

.admin-red-dash {
  color: #F93C65;
  font-size: 15px;
}

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: #fff;
  color: #222;
  border-radius: 8px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  /* border: 1px solid #a0a096; */
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  color: #6f48eb;
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #6f48eb;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  color: #6f48eb;
  border-radius: 6px;
}

.react-calendar__tile--now {
  background: #646269;
  border-radius: 6px;
  font-weight: bold;
  color: #fff;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #6f48eb33;
  border-radius: 6px;
  font-weight: bold;
  color: #6f48eb;
}

.react-calendar__tile--hasActive {
  background: #6f48eb;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
}

.react-calendar__tile--active {
  background: #6f48eb;
  border-radius: 6px;
  font-weight: bold;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #6f48eb;
  color: white;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
}

.react-calendar__tile--range {
  background: #bfb3e8;
  color: #6f48eb;
  border-radius: 0;
}

.react-calendar__tile--rangeStart {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: #6f48eb;
  color: white;
}

.react-calendar__tile--rangeEnd {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background: #6f48eb;
  color: white;
}

.admin-switch-btn {
  border-radius: 9px;
  border: 4px solid #5C5AB6;
  background: #EAE9FB;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.20);
  color: #5C5AB6;
  font-family: Nunito Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.admin-switch-btn-deactivate {
  border-radius: 5px;
  border: 1px solid #252525;
  background: #EAE9FB;
  font-family: Nunito Sans;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.admin-navbar-search-btn {
  border-radius: 3px;
  background: #5C5AB6;
}

.admin-navbar-search-btn:hover {
  border-radius: 3px;
  background: #5C5AB6;
}

.admin-nav-icon {
  width: 25.719px;
  height: 25px;
  cursor: pointer;
}


.admin-verified-btn {
  color: #5C5AB6;
}

.admin-horizontalLine {
  width: 70%;
}

.admin-add-icon {
  width: 33px;
  height: 33px;
  color: #5C5AB6;
}


.admin-dotted-border {
  border: 2px dashed #000;
  padding: 10px;
}

.admin-signIn-text {
  color: var(--Default, #172B4D);
  font-family: Open Sans;
  font-size: 37.643px;
  font-style: normal;
  font-weight: 700;
  line-height: 137%;
}

.admin-signup-text {
  color: var(--Default, #172B4D);
  text-align: center;
  font-family: Open Sans;
  font-size: 24.574px;
  font-style: normal;
  font-weight: 600;
}


.admin-signIn-para {
  color: var(--Secondary, #8392AB);
  font-family: Open Sans;
  font-size: 17.567px;
  font-style: normal;
  font-weight: 400;
  line-height: 142%;
}

.admin-signIn-input {
  border-radius: 10.038px;
  border: 1.255px solid #9E9E9E;
  background: #FFF;
}

.admin-signIn-Remember {
  color: var(--Default, #172B4D);
  font-family: Open Sans;
  font-size: 20.076px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.admin-signIn-switch {
  background-color: #D7D6F2;
}

.admin-signIn-btn {
  width: 100%;
  height: 50px;
  border-radius: 10.038px;
  background: #5C5AB6;
  box-shadow: 0px 2.50955px 7.52866px 0px rgba(0, 0, 0, 0.25);
}


.admin-signIn-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  background: #7E7CD4;
  box-shadow: none;
}



.admin-signIn-btn:hover {
  width: 100%;
  height: 50px;
  border-radius: 10.038px;
  background: #5C5AB6;
  box-shadow: 0px 2.50955px 7.52866px 0px rgba(0, 0, 0, 0.25);
}

.admin-signIn-btn-text {
  color: var(--White, #FFF);
  text-align: right;
  font-family: Open Sans;
  font-size: 17.567px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  /* 26.35px */
}

.admin-signIn-account {
  color: var(--Secondary, #8392AB);
  text-align: center;
  font-family: Open Sans;
  font-size: 17.567px;
  font-style: normal;
  font-weight: 400;
  line-height: 142%;
}

.admin-signIn-account-signUp {
  color: #5C5AB6;
  font-family: Open Sans;
  font-size: 17.567px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  cursor: pointer;
}

.chooseFile-uploader {
  color: #fff;
  background: #8280FF;
}

.admin-horizontal-line {
  height: 2px;
  background-color: #252525;
  border: none;
}

.addButton-upload {
  border-radius: 4px;
  border: 1px solid #252525;
  background: #D9D9D9;
}

.addButton-upload-text {
  color: #202224;
  font-family: Nunito Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}


.uploade-review-btn {
  border: 0.869px solid #000;
  background: #FFF;
  color: #000;
}

.uploade-review-btn:hover {
  border: 0.869px solid #000;
  background: #FFF;
  color: #000;
}

.settingsActive {
  border-radius: 33px;
  border: 1px solid #FFF;
  background: linear-gradient(90deg, #030093 0%, rgba(57, 54, 170, 0.87) 28.13%, rgba(123, 121, 198, 0.71) 66.15%, rgba(255, 255, 255, 0.40) 100%);
  color: #fff;
}

.teacher-main-head {
  color: #202224;
  font-family: Nunito Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.114px;
}

.techer-card-typo {
  color: #252525;
  font-family: Nunito Sans;

  font-style: normal;
  font-weight: 600;
  line-height: normal;
}


.landing-online-txt {
  color: #FFF;
  font-family: Nunito Sans;
  font-size: 26px;
  font-style: normal;
  font-weight: 650;
}


.landing-email-inp {

  border: 1px solid #83839A;
  background-color: #252641;
  color: white;
  text-align: start;
  font-family: Nunito Sans;

}

.landing-email-inp {
  &::placeholder {
    color: #83839A;
  }
}

.landing-card-head {
  color: #252525;
  font-family: Nunito Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.12px;
}

.landing-card {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px -1px 21px 0px rgba(0, 0, 0, 0.10);
}

.admin-sub-doc-head {
  color: #000;
  font-family: Nunito Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.65px;
}

.landing-app-card-violet {
  border-radius: 14.653px;
  background: #5C5AE9;
}

.landing-card-vio-txt {
  color: #FFF;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 25.311px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.landing-download-txt-and {
  color: #FFF;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 11.989px;
  font-style: normal;
  font-weight: 600;
  line-height: 11.989px;
  /* 100% */
}

.landing-btn-app-violet {
  border-radius: 22.646px;
  background: #5C5AE9;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
}

.landing-btn-app-violet:hover {
  border-radius: 22.646px;
  background: #5C5AE9;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
}

.landing-violet-card {
  border-radius: 14.653px;
  border: 1.332px solid #5C5AE9;
  background: #FFF;
}

.landing-card-wht-txt {
  color: #5C5AE9;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 25.311px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}


.landing-btn-app-white {
  border-radius: 22.646px;
  background: #FFF;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
}


.landing-btn-app-white:hover {
  border-radius: 22.646px;
  background: #FFF;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
}

.landing-download-txt {
  color: #5C5AE9;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 11.989px;
  font-style: normal;
  font-weight: 600;
  line-height: 11.989px;
  /* 100% */
}

.landing-download-txt:hover {
  color: #5C5AE9;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 11.989px;
  font-style: normal;
  font-weight: 600;
  line-height: 11.989px;
  /* 100% */
}

.verification_back_txt {
  color: #000;
  text-align: right;
  font-family: Nunito Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 27px */
}

.verification_back_txt_2 {
  color: #000;
  font-family: Nunito Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 30px */
}

.landing_position_absolute {
  position: absolute;

}

.landng_contact {
  background: #5C5AB6;
}

.landing-home-txt {
  position: absolute;
  bottom: 0;
  left: 2%;
  width: 100%;
}




.landing-main-sub-txt {
  color: #FFF;
  font-family: Nunito Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.84px;
}

.landing-btn {
  border-radius: 5px;
  background: #252525;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.landing-btn:hover {
  border-radius: 5px;
  background: #252525;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.landing-main-txt {
  color: #FFF;
  font-family: Nunito Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.84px;
}

.signUp_txt {
  color: #263238;
  font-family: Nunito Sans;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}



.identity_container {
  background: linear-gradient(180deg, #8280FF 0%, #3936CC 100%);
  box-shadow: 0px 3.48914px 3.48914px rgba(0, 0, 0, 0.25), inset 0px 3.48914px 3.48914px rgba(0, 0, 0, 0.25);
  border-radius: 12.7822px;
  width: 100%;
  height: 230px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: center;
  background-color: rgb(48, 87, 123);
  border-radius: 10px;
  box-shadow: #000;

  &:hover {
    cursor: pointer;
    background: linear-gradient(180deg, #2f2d2d 0%, #000 70%)
  }

  img {
    padding: 20px;
    width: auto;
    height: 140px;
  }

  h3 {
    font-size: 22px;
    font-weight: bold;
    color: #fff;
  }
}


.signup_toggle_head {
  color: #263238;
  font-family: Nunito Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.signIn_label {
  color: #263238;
  font-family: Nunito Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.Teacher_announcement_Active {
  background: linear-gradient(90deg, #FFF -137.07%, rgba(255, 255, 255, 0.00) 100%);
}


/* Media query for screens smaller than 768px (typical smartphones) */
@media only screen and (max-width: 767px) {
  .admin-gray-text {
    font-size: 14px;
  }


  /* .lading-navbar {
    display: none;
  } */


  .admin-cst-label {
    font-size: 14px;

  }

  .admin-cst-label {
    font-size: 14px;
  }

  .admin-sub-head {
    font-size: 20px;

  }

  .admin-sub-head-card {
    font-size: 16px;
  }

  .admin-font-message {
    font-size: 12px;
  }

  .landing-margin-top {
    margin-top: 250px;
  }




}





/* Media query for screens between 768px and 991px (typical tablets) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .admin-gray-text {
    font-size: 15px;

  }


  /* .lading-navbar {
    display: none;
  } */


  .admin-cst-label {
    font-size: 15px;

  }

  .admin-cst-label {
    font-size: 15px;
  }

  .admin-sub-head {
    font-size: 24px;
  }

  .admin-sub-head-card {
    font-size: 20px;

  }

  .admin-font-message {
    font-size: 15px;
  }

}

/* Media query for screens between 992px and 1199px (larger desktops) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .admin-gray-text {
    font-size: 11px;
  }


  .admin-green-dash {
    font-size: 11px;

  }


  .admin-cst-label {
    font-size: 16px;

  }

  .admin-sub-head {
    font-size: 24px;
  }

  .admin-sub-head-card {
    font-size: 20px;

  }

  .admin-font-message {
    font-size: 16px;
  }


  .landing-main-txt {
    font-size: 20px;
  }


}

/* Media query for screens 1200px and larger (extra-large screens) */
@media only screen and (min-width: 1200px) {
  .admin-gray-text {
    font-size: 15px;
  }

  .admin-cst-label {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }

  .admin-sub-head {
    font-size: 24px;
  }

  .admin-sub-head-card {
    font-size: 20px;
  }

  .admin-font-message {
    font-size: 18px;
  }

}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body {
  overflow-x: hidden;
}


.teacher-profile-card {
  background-color: #F5F6FA;
}

.teacher-profile-card {
  font-size: 20px;
}

.input-border-teacher {
  border-radius: 3px;
  border: 1px solid #263238;
}

.profile-main {
  background-color: #5C5AB6;
  color: #fff;
}

.signIn_label_tea {
  font-family: Nunito Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;

}

.admin-signIn-input-tea {
  border-radius: 10px;
  border: 1.08px solid #000;
  background: #FFF;

}

.chat-container {
  position: relative;
  width: 100%;
  /* height: calc(100% - 120px);  60+60 */
  /* padding: 50px; */
  overflow-y: auto;
}

.chat-scrollable-section {
  background: #EEE;
}

.message-box {
  position: relative;
  display: flex;
  width: 100%;
  margin: 5px 0;
}

.message-box p {
  position: relative;
  right: 0;
  /* text-align: right; */
  /* max-width: 65%; */
  padding: 12px;
  background: #dcf8c6;
  border-radius: 10px;
  font-size: 15px;
  color: #000;
  font-weight: 600;

  font-size: 0.9em;
}

.message-box.my-message p::before {
  content: '';
  position: absolute;
  top: 0;
  right: -12px;
  width: 20px;
  height: 20px;
  background: linear-gradient(135deg, #dcf8c6 0%, #dcf8c6 50%, transparent 50%, transparent);
}

.message-box p span {
  display: block;
  margin-top: 5px;
  font-size: 0.8em;
  opacity: 0.5;
}

.my-message {
  justify-content: flex-end;
  justify-content: end;
}

.friend-message p {
  background: #e3c5e4;
  color: #000;
  font-size: 15px;
  font-weight: 600;
}

.friend-message {
  justify-content: flex-start;

}

.chat-container .my-message i {
  color: yellow;
}

.message-box.friend-message p::before {
  content: '';
  position: absolute;
  top: 0;
  left: -12px;
  width: 20px;
  height: 20px;
  background: linear-gradient(225deg, #e3c5e4 0%, #e3c5e4 50%, transparent 50%, transparent);

}

.send-file {
  background-color: #dcf8c6;

}

.receive-file {
  background-color: #e3c5e4;

}

.send-file-msg {
  text-decoration: none;
  color: #000;
}

.batch_text {
  color: #252525;
  font-family: "Nunito Sans";
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.teacher_profile_pic {
  height: 150px;
  width: 170px;
  border-radius: 50%;
}

.msg_box_teacher {
  border: 1px solid black;
  height: 250px;
}

.delete-modal {
  font-size: 20px;
}

.cancel-btn {
  font-size: 20px;
}

.main-divHead {
  /* margin-left: 20px; */
  font-size: 15px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: #EAE9FB;
  border-radius: 5px;
  height: auto;
  width: auto;
  overflow-x: hidden;
  overflow-y: hidden;

}

.sub-Head1 {
  height: 50px;
  width: auto;
  background-color: #5C5AB6;
  padding-left: 35px;
  padding-top: 15px;
  color: whitesmoke;
  font-size: 14px;
  display: flex;
  border-radius: 10px;

}

.Sub-Head2 {
  padding-left: 10px;
}

.btn-req-drawal {
  background-color: #5C5AB6;
  border: 1px solid #5C5AB6;
  border-radius: 5px;
  border-width: 5px;
  color: whitesmoke;
}

.Category_drop {
  font-size: 16px;
}

.unread_tick {
  color: #34b7f1
}

.btnStudent {
  background-color: #00b67f;
}

.btnStudent {
  background-color: #00b67f !important;
}

.btnActive_in {
  background-color: #00b67f;
}

.btnActive_in:hover {
  background-color: #00b67f !important;
}

.bsRwzy {
  overflow: visible !important;
}

.btn_save_chap {
  width: 100px;
}

.syllabus_btn {
  background-color: #5C5AB6 !important;
  color: whitesmoke !important;
  font-size: 20px;
  font-family: sans-serif;
  border-radius: 5px;
}

.end-of-div-icon {
  position: absolute !important;
  right: 0 !important;
  bottom: 0 !important;
  transform: translate(50%, 50%) !important;
}



/* .main_row {
  background-color: rgb(255, 255, 255);
} */

@media screen and (max-width: 320px) {
  .bootBtn {
    font-size: 22px;
  }
}

@media screen and (min-width: 321px) and (max-width: 375px) {
  .bootBtn {
    font-size: 20px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1016px) {
  .bootBtn {
    font-size: 12px;
  }
}

@media screen and (max-width: 1024px) {
  .bootBtn {
    font-size: 22px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .subCategoryDropdown {
    font-size: 15px !important;
    font-weight: bolder;
  }
}

.plus-button {
  background-color: #5C5AB6;
  color: white;
  border-radius: 50%;
  cursor: pointer;
  border: none;
}



/* 
.main_row {
  background-color: rgb(189, 185, 185);
} */

.inp_schedule {
  width: 100px;
}


@media screen and (max-width: 320px) {
  .inp_schedule {
    width: 50px;
  }

  .dates_sched {
    font-size: 8px;
  }

  .dates_wed {
    font-size: 10px;
  }

}

@media screen and (min-width: 100px) and (max-width: 375px) {
  .inp_schedule {
    width: 50px;
  }

  .dates_sched {
    font-size: 10px;
  }

  .dates_wed {
    font-size: 10px;
  }

  .review_heading {
    font-size: 11px;
  }

  .p_review {
    font-size: 8px;
  }
}

@media screen and (min-width: 376px) and (max-width: 500px) {
  .inp_schedule {
    width: 50px;
  }

  .dates_sched {
    font-size: 12px;
  }

  .dates_wed {
    font-size: 10px;
  }

  .review_heading {
    font-size: 14px;
  }

  .p_review {
    font-size: 10px;
  }

}

@media screen and (min-width: 501px) and (max-width: 800px) {
  .inp_schedule {
    width: 50px;
  }

  .dates_sched {
    font-size: 10px;
  }

  .dates_wed {
    font-size: 10px;
  }

  .review_heading {
    font-size: 18px;
  }
}

@media screen and (min-width: 801px) and (max-width: 991px) {
  .inp_schedule {
    width: 80px;
  }

  .dates_sched {
    font-size: 10px;
  }

  .dates_wed {
    font-size: 10px;
  }
}

@media screen and (min-width: 801px) and (max-width: 812px) {
  .inp_schedule {
    width: 60px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1016px) {
  .inp_schedule {
    width: 80px;
  }

  .dates_sched {
    font-size: 14px;
  }

  .dates_wed {
    font-size: 10px;
  }

  .review_heading {
    font-size: 20px;
  }
}

@media screen and (min-width: 1016px) and (max-width: 1024px) {
  .bootBtn {
    font-size: 12px;
  }

  .dates_sched {
    font-size: 12px;
  }

  .dates_wed {
    font-size: 10px;
  }

  .review_heading {
    font-size: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1199px) {
  .bootBtn {
    font-size: 18px;
  }

  .dates_sched {
    font-size: 12px;
  }

  .dates_wed {
    font-size: 10px;
  }

  .inp_schedule {
    width: 80px;
  }

  .review_heading {
    font-size: 25px;
  }

}

@media screen and (min-width: 1199px) and (max-width: 1401px) {
  .inp_schedule {
    font-size: 12px;
    width: 80px;
  }


}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .inp_schedule {
    width: 70px;
  }

  .dates_sched {
    font-size: 12px;
  }

  .dates_wed {
    font-size: 10px;
  }

  .review_heading {
    font-size: 35px;
  }
}

@media screen and (min-width: 1401px) and (max-width: 2001px) {
  .inp_schedule {
    width: 80px;
  }

  .dates_sched {
    font-size: 14px;
  }

  .dates_wed {
    font-size: 12px;
  }

  .review_heading {
    font-size: 50px;
  }
}




.img_up {
  height: 100px;
}


.table_course {
  cursor: pointer;
}

.btn_sub {
  /* background-color: #5C5AB6; */
}

.sub_category {
  border-radius: 20px;
}

.plus_icon {
  background-color: whitesmoke;
}

.select-wrapper {
  position: relative;
  display: inline-block;
  /* Ensure the wrapper only takes up necessary width */
  width: calc(100% - 10px);
  /* Adjust the width to accommodate the icon */
}

.plus-icon-container {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.plus_icon {
  /* Your styles here */
}

.star_req {
  color: red;
}

.error-icon {
  font-size: 30px;
  color: red;
  margin-right: 10px;
  margin-bottom: 10px;
  /* Add margin for spacing */
  animation: blink-animation .5s infinite alternate;
  /* Add animation to the error icon */
}

.error-message {
  font-weight: 800;
  font-size: 18px;
}

@keyframes blink-animation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.btn_review {
  background-color: #5C5AB6;
  color: whitesmoke;
}

.error-container {
  display: inline-flex;
  /* or inline-block depending on your layout needs */
  align-items: center;
  /* Align items vertically if needed */
}

.review_heading {
  font-family: Nunito Sans;
}

.p_review {
  font-family: Nunito Sans;
}




.custom-tabs .nav-link {
  color: #5C5AB6;
}
.custom-tabs .nav-link.active {
  background-color: #5C5AB6 !important;
  color: white !important;
}

