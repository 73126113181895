.Main-head-viewschedule {
    color: #263238;
    font-family: Nunito Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.05px;
    margin-top: 40px;
    margin-left: 40px;
}

.Teacher-view-schedule {
    color: black;
    font-size: 18px;
    padding-left: 50px;
    margin-left: 150px;
    margin-top: 50px;
}

.view-schedule-details {
    margin-left: 200px;
    margin-top: 50px;
    row-gap: 20px;
    color: #252525;
font-family: Nunito Sans;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.p1-view-schedule {
    float: left;
    padding: 0px 50px;
}

.view-schedule-details2 {
    margin-left: 200px;
    margin-top: 20px;
    color: #252525;
font-family: Nunito Sans;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
   
}

.p2-view-schedule {
    margin-left: 250px;
}

.view-schedule-details3 {
    margin-left: 200px;
    margin-top: 20px;
    color: #252525;
font-family: Nunito Sans;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
    
}
.Title-main-head{
 
}