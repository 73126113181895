.btn-create-schedule {
    background-color: #5C5AB6;
    border: 1px solid #5C5AB6;
    border-radius: 5px;
    border-width: 5px;
    color: whitesmoke;
    width: 15%;
    margin-left: 50px;
    margin-top: 50px;
}

.delete-popup {
    background-color: #f7d1ff;
    height: 100px;
    width: 300px;
    text-align: center;
    border-radius: 5px;
    margin-left: 200px;
}

.accnt-details-card1 {
    background: #ece4e4;
    margin-left: 250px;
    width: 400px;
    height: 180px;
    margin-top: 80px;
    border-radius: 5px;
    text-align: center;
}

.para1-delete {

    padding-top: 30px;
    font-size: 28px;
}

.yes-btn {
    background-color: #5C5AB6;
    border: 1px solid #5C5AB6;
    border-radius: 5px;
    border-width: 5px;
    color: whitesmoke;
    width: 10%;
    padding-left: 10px;
    margin-top: 50px;
}

.cancel-btn {
    background-color: #5C5AB6;
    border: 1px solid #5C5AB6;
    border-radius: 5px;
    border-width: 5px;
    color: whitesmoke;
    width: 15%;
    margin-top: 50px;

}

/* Styles for the modal overlay */
// .ReactModal__Overlay {
//     background-color: rgba(0, 0, 0, 0.5);
//     backdrop-filter: blur(1px);
//     // display: flex;
//     justify-content: center;
//     align-items: center;
//     margin-left: 0px;
//     height: 90vh;
//     width: auto;

// }

/* Styles for the modal content */


.para-delete {
    padding-top: 100px;
}

.delete-img {
    height: 100px;
    width: 100px;
}

/* Your existing styles */

/* New CSS styles for the view */
.icon-view-container {
    position: relative;
}

.show-view {
    position: absolute;
    bottom: 0px;
    /* Adjust this value based on your design */
    right: 30px;
    background-color: #fff;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    animation: slideIn 0.3s ease;
    width: 100px;
    margin-left: 80px;
    /* Animation definition */
}

/* Animation definition */
@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}
