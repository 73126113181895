.notifi-stu {
  margin-top: 2rem; /* Adjust the top margin for responsiveness */
  font-weight: 800;
}

.schedule-stu {
  margin-top: 2rem; /* Adjust the top margin for responsiveness */
  font-weight: 800;
}

.student-Notifi {
  cursor: pointer;
  // background-color: white;
  /* border: 1px solid  #767C80; */

  &.active {
    .notifi-stu {
      color: #5C5AB6;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.student-Schedule {
  // background-color: white;
  cursor: pointer;

  &.active {
    .schedule-stu {
      color: #5C5AB6;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.notifi-img {
  // width: 3rem; /* Adjust the image size for responsiveness */
  // height: auto;
  border-radius: 5px;
}

.note {
  justify-content: flex-start;
  margin-top: 2rem; /* Adjust the top margin for responsiveness */
}

.notifi-head {
  justify-content: flex-end;
  margin-top: 25px;

}

.notifi-grid {
  margin-top: 2rem; /* Adjust the top margin for responsiveness */
}

