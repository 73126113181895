.Enrolled {
    margin-left: 35px;
    margin-top: 10px;
}

.faChevronLeft {
    margin-left: 12px;
    margin-top: 10px;
}
.chevron-head{
    padding: 10px;
}

.head-enroll {
    color: white;
    background-color: #5C5AB6;
    height: 43px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.book-imgs {
    width: 176px;
    height: 126px;
}

.english {
    padding-top: 11px;
    width: 200px;
    background-color: white;
}

.open {
    width: 70px;
    background: #5C5AB6;
    mix-blend-mode: normal;
    opacity: 0.7;
    border: none;
    color: white;
    border-radius: 5px;
    margin-left: 7pc;
    margin-bottom: 18px;
    cursor: pointer;
}

.detailed-content {
    background-color: #F5F6FA;
}