.class-img {
    height: 170px;
    width: 264px;
    // margin-left: -22px;
}

// .fa-star{
//     font-size: 10px;
//     padding-top: 5px;
// }
.name-card {
    padding-top: 5px;
    padding-left: 15px;
}

.name {
    font-size: 10px;
}

.count {
    font-size: 10px;
}

.course-detail {
    // height: 700px;
    // width: 260px;
    margin-left: 20px;
    // margin-top: 80px;
    background-color: white;
    border-radius: 0px;
    padding: 0px;
}

.preview {
    width: 120px;
    border-color: #5C5AB6;
    background-color: white;
    color: #5C5AB6;
    margin-left: 30px;
    font-size: 11px;
    padding-left: 5px;
    border-width: 1px;
}

.para-course {
    color: #263238;
    font-family: Nunito Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.my-sub-des {
    color: #263238;
    font-family: Nunito Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.sub-sidehead-course {
    color: #263238;
    font-family: Nunito Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.sub-preview-btn {
    color: #5C5AB6;
    font-family: Nunito Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.26px;
}

.sub-rate-para {
    color: #263238;
    font-family: Nunito Sans;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.sub-rating-star {
    stroke-width: 0.409px;
    stroke: #000;
}

.sub-icon-btn {
    border-radius: 2.781px;
    border: 0.927px solid #5C5AB6;
}

.course-rating {
    font-size: 12px;
}

.td {
    max-width: 150px;
}

.table tbody tr th {
    border: none !important;
    border-bottom-width: 0px !important;
}

.record {
    // position: relative;
    // margin-top:35px;
    justify-content: center;
    padding: 10px 0px;
}

.record-btn {
    background-color: #5C5AB6;
    color: white;
    padding: 5px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    outline: none;
}

.record-icon {
    padding-top: 2px;
    padding-right: 6px;
}

.preview {
    justify-content: center;
}

.bio {
    // margin-top: 82px;
    // margin-left: 100px;
    // display: flex;
    // flex-direction: column;
    background-color: white;
    // width: 1000px;
    border-radius: 6px;
    padding: 0px;
}

.bio-descrpition {
    font-weight: 600;
}

.head-bio {
    // position: absolute;
    // top: 20px;
    /* right: 10px; */
    // left: 23px;
}

.liveclass-container {
    background-color: #EAE9FB;
    border-radius: 20px;
}

.chat-action {
    color: black;
}

.live-card {
    // margin-top: 80px;
}

.bio-img {
    width: 100px;
    height: 100px;
    border-radius: 3px;
}

.live-class {
    color: #263238;
    font-family: Nunito Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.book-img1 {
    width: 340px;
}

.subject-edu {
    color: #263238;
    font-family: Nunito Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.4px;
}

.sub-bio-des {
    color: #263238;
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
}

.sub-bio-mainHead {
    color: #000;
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.sub-bio-head {
    color: #263238;
    font-family: Nunito Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.4px;
}

.sub-des-about {
    color: #263238;
    font-family: Nunito Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.sub-time {
    color: #000;
    font-family: Nunito Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.subj-dat {
    color: #263238;
    font-family: Nunito Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.sub-rev-name {
    color: #000;
    font-family: Nunito Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.sub-review-img {
    border-radius: 55.113px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 10 0%), lightgray 50% / cover no-repeat;
}

.admin-card-sub {
    position: relative;
    border-radius: 14px;
    background: #FFF;
    box-shadow: 6px 6px 54px 0px rgba(0, 0, 0, 0.05);
    transition: .5s;
    align-items: center;
    border-radius: 4.003px;
    background: #FFF;
    box-shadow: 0px 4.00345px 4.00345px 0px rgba(0, 0, 0, 0.25);
}

.sub-rev-des {
    color: #000;
    font-family: Nunito Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.course-hr {
    background-color: #263238;
}

.live-img {
    display: flex;
}

.text-muted-rating {
    // font-size: 10px;
    // padding-left: 15px;
}

.teacher-name {
    display: flex;
    /* justify-content: flex-start; */
    position: absolute;
    left: 158px;
    top: 39px;
}

.table-timing {
    font-size: 12px;
    padding: 5px;
}

.teacher-meera {
    font-weight: 600;
}

.bio-des {
    margin-top: 37px;
    margin-left: 10px;
}

.education-bio {
    flex-direction: column;
}

.bio-education {
    margin-top: 10px;
    margin-bottom: 10px;
    // margin-left: 10px;
}

.review-section {
    // padding: 30px 0px;
}

.reiview-des {
    padding: 0px;
}

.file-size {
    font-size: 10px;
}

.purchase-btn {
  border-radius: 4.122px;
  border: 1.374px solid #5C5AB6;
  box-shadow: 0px 2.061px 6.87px 0px rgba(0, 0, 0, 0.25);
  color: #5C5AB6;
  font-family: Nunito Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.32px;
}

.review-bio-img {
    width: 40px;
    height: auto;
    border-radius: 50%;
}

.review-name {

    padding-left: 10px;
}

.show-all {
    background-color: white;
    font-size: 12px;
    display: flex;
    align-items: center;
    padding: 5px;
}

.show-review {
    padding-bottom: 20px;
    justify-content: center;
}

.course-content {
    padding: 30px 0px 20px 60px;
    width: 600px;
    height: auto;
    display: flex;
    justify-content: flex-start;
    background-color: white;
    border-radius: 3.3px;
    margin-left: 20px;
}



.upload-assign {
    // margin: 24rem;
}

.Get-Started1 {
    width: 400px;
    background-color: #FAFAFA;
    display: flex;
    width: 400px;
    padding: 5px 0px 0px 5px;
    margin-bottom: 5px;
}

.Get-Started {
    // width: 400px;
    background-color: rgb(227, 220, 220);
    display: flex;
    width: 400px;
    padding: 5px 0px 0px 5px;
}

.arrow-down {
    padding-left: 260px;
}

.content-1,
.content-2,
.content-3,
.content-4,
.content-5 {
    padding: 10px 0px;
}

.english-img {
    padding: 30px 0px 0px 30px;
}

.english-for {
    background-color: #5C5AB6;
    // padding: 30px 0px 0px 30px;
    color: white;
}

.subject-img {
    width: 40px;
    border-radius: 50%;
}

.english-section {
    padding: 20px 0px 0px 10px;
}

.about-subject {
    padding-left: 10px;
    padding-bottom: 10px;
    font-size: 12px;
}

.view-link {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    padding-bottom: 10px;
}

.Profile-link {
    font-size: 12px;
    color: white;
    text-decoration: none;
}

.review {
    width: 480px;
    height: 190px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 6px 10px rgba(0, 0, 0, .08), 0 0 6px rgba(0, 0, 0, .05);
    transition: .3s transform cubic-bezier(.155, 1.105, .295, 1.12), .3s box-shadow, .3s -webkit-transform cubic-bezier(.155, 1.105, .295, 1.12);
    margin: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: 1px solid;
    padding: 0px;
    // .card{
    //     // position: relative;
    // border-radius: 15px;
    // padding: 40px 20px;
    // box-shadow: 0px 10px 60px rgba(38, 45, 118, 0.08);
    // transition: 0.5s;
    // // align-items: center;
    // /* display: flex; */
    // // justify-content: center;
    // width: 300px;
    // }
}


.student-sub-card-head {
    font-size: 10px;
}



@media(max-width: 990px) {
    .card {
        margin: 20px;
    }
}

.bio-data {
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    font-size: 13px;
}

.edctn {
    font-size: 12px;
    font-weight: 600;
    color: #263238;
}

.University {
    font-size: 12px;
}

.bio-1 {
    font-weight: 600;
    font-size: 12px;
}

.chat-1 {
    position: absolute;
}

.chat-search-input {

    position: relative;
}

.chat-input {
    border: none;
    /* background-color: #eae9fb; */
    padding: 5px 10px;
}

//place oreder scss
.video-content {
    justify-content: space-around;
}

.buy-now {
    padding: 5px 10px;
    background-color: #5C5AB6;
    color: white;
    border: none;
}

.course-profile {
    padding: 5px;
}

/* Media query for screens smaller than 768px (typical smartphones) */
@media only screen and (max-width: 767px) {
    .admin-card-sub {
        font-size: 10px;
    }

}



/* Media query for screens smaller than 768px (typical smartphones) */
@media only screen and (max-width: 767px) {
    .student-sub-card-head {
        font-size: 10px;
    }

}

/* Media query for screens between 768px and 991px (typical tablets) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .student-sub-card-head {
        font-size: 10px;
    }
}

/* Media query for screens between 992px and 1199px (larger desktops) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .student-sub-card-head {
        font-size: 16px;
    }

}

/* Media query for screens 1200px and larger (extra-large screens) */
@media only screen and (min-width: 1200px) {
    .student-sub-card-head {
        font-size: 16px;
    }

}
.student-main-head{
background-color: #5C5AB6 !important;
color: #FFF;
}