.new-quotation {
  margin: 40px 20px 30px 0px;
}
.quote {
  outline: none;
  margin-left: 40px;
  background-color: #5c5ab6;
  color: white;
  border: none;
  padding: 5px;
  font-weight: 700;
  display: flex;
  align-items: center;
  border-radius: 5px;
}
.test-report-head {
  color: #263238;
  font-family: Nunito Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.test-font-label {
  color: #000;
  font-family: Nunito Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.test-report-student{
  // text-align: center;
}
.details-table-btn {
  background-color: #5c5ab6;
  border-radius: 4px;
  color: #fff;
  border: none;
}
.questions-card {
  // width: 600px;
  padding: 13px;
  display: flex;
  justify-content: flex-start;
  background-color: white;
  width: auto;
  height: auto;
}
.question-content {
  background-color: white;
}
.des-1 {
  border: 1px solid black;
}
.des-2 {
  justify-content: center;
  padding: 15px;
}
.next-btn {
  background-color: #5c5ab6;
  color: white;
  outline: none;
  border: none;
  padding: 5px 20px;
}
.test-card {
  background-color: #eae9fb;
  // margin-top: 20px;
  display: flex;
  justify-content: center;
  padding: 20px;
}
.card-descriptio-ques {
  border: 1px solid;
}
.ques-img {
  padding-left: 90px;
  padding-right: 10px;
}
.ques-opt {
}
.ques-ans {
  font-weight: 600;
}
.load-img {
  padding-left: 20px;
}
.next-btn {
  justify-content: flex-end;
}
.button-5 {
  background-color: #5c5ab6;
  padding: 5px 15px;
  border: none;
  color: white;
}
.test-ul {
  list-style: none;
}
.report {
  width: 600px;
}
.testHr {
  // color: black;
  // border: 1px solid black;
  // opacity: 0;
}
.test-head{
  font-family: serif;
  font-size: 70px;
  border-bottom: 4px rgb(46, 4, 4) dashed;
}
