.quick_demo_page {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  overflow: hidden;

  .landing_navbar {
    align-items: center;
    // padding: 0 130px 0 20px;
    height: 70px;
    background-color: #ffffff;
    color: rgb(48, 87, 123);
    display: flex;
    // width: 100vw;
    display: flex;
    justify-content: space-between;
    position: fixed;
    z-index: 3;
    // border-bottom: 1px solid rgb(48, 87, 123);
    box-shadow: 1px 1px 10px rgb(48, 87, 123);

    .navigation_content {
      display: flex;
      justify-content: space-between;
      gap: 140px;
      align-items: center;
      color: rgb(48, 87, 123);

      .navigation {
        display: flex;
        justify-content: space-between;
        gap: 70px;
        font-weight: bold;

        p:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .sign_in {
        display: flex;
        align-items: center;
        gap: 20px;

        button {
          // font-size: 13px;
          // font-weight: bold;
          border-radius: 30px;
          background-color: rgb(48, 87, 123);
          color: rgb(255, 255, 255);
          padding: 5px 10px;
          letter-spacing: 1px;
          font-weight: bolder;
          border-color: transparent;

          &:hover {
            cursor: pointer;
            // background-color: rgba(255, 255, 255, 0.3);
            text-decoration: none;
            color: rgb(39, 189, 1);
          }
        }
      }
    }
  }
  .fixed {
    position: fixed;
    // opacity: 0.5;
    z-index: 3;
  }
}



//Provisions
.choices_option_container {
  display: flex;
  flex-direction: column;
 
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;

  .choices_head {
    margin-right: 25px;
    font-size: 28px;
    font-weight: 700;
    color: black;
  }

  .choices_container {
    display: flex;
    flex-direction: column;
    gap: 50px;

    .choices_row {
      display: flex;
      justify-content: space-between;
      width: 60vw;

      .offerings {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
    }
  }
}

//footer
.footer_container {
  width: 100vw;
  height: 300px;
  padding: 30px 0 0 150px;
  background-color: #30577b;
  display: flex;
  gap: 40px;
  color: #ffffff;

  .footer_col {
    display: flex;
    flex-direction: column;
    gap: 10px;

    h4 {
      margin: 0 0 20px 0;
    }
  }
}
