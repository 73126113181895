.Main-title-head1 {
    padding-left: 50px;
    margin-top: 50px;
    color: black;
    font-size: 22px;

}

.create-assign-inp1 {
    padding-left: 20px;
    margin-left: 100px;
    margin-top: 10px;
    color: black;
    float: left;
    outline: none;
    border-radius: 5px solid;
}

#create-assign-category {
    font-size: 16px;
    margin-top: 20px;
    outline: none;
}

#create-assign-drop1 {
    width: 200px;
    height: 30px;
    border-radius: 5px solid;
}

.create-assign-inp3 {
    padding-left: 20px;
    margin-left: 750px;
    margin-top: 10px;
    color: black;
}

#create-assign-drp3 {
    font-size: 16px;
    margin-top: 20px;
}

#create-assign-drop4 {
    width: 200px;
    height: 30px;
    border-radius: 5px solid;
}

#create-assign-drop5 {
    width: 400px;
    height: 80px;
    border-radius: 5px solid;
    outline: none;
}

.create-assign-inp5 {
    padding-left: 20px;
    margin-left: 500px;
    margin-top: 10px;
    color: black;
    border-radius: 5px solid;
    outline: none;
}

#create-assign-drop6 {
    width: 250px;
    height: 50px;
    border-radius: 5px;
    border: 1px solid black;

    padding-top: 15px;
    padding-left: 20px;

}

.create-assign-inp7 {
    padding-left: 20px;
    margin-left: 500px;
    margin-top: 30px;
    color: black;
}

#create-assign-drop7 {
    background-color: #5C5AB6;
    border: 1px solid #5C5AB6;
    border-radius: 5px #5C5AB6;
    border-width: 5px;
    color: whitesmoke;
    width: 15%;
    margin-top: 60px;
    margin-left: 280px;

}

.value-box {
    outline: none;
    border-radius: 5px solid;
}