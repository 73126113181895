.Main-Notification-boxs {
    margin-left: 360px;
    margin-top: 20px;
    color: black;
}
.btn-submit-notification{
    background-color: #5C5AB6;
    border: 1px solid #5C5AB6;
    border-radius: 5px;
    border-width: 5px;
    color: whitesmoke;
    width: 10%;
    margin-left: 45vh;
    margin-top: 50px;
}
.subtitle-headings{
    color: #263238;

font-family: Nunito Sans;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
