.student-complete-assign {
    height: 60vh;
    width: 120vh;
    background-color: whitesmoke;
    color: black;
    font-size: 18px;
    padding-left: 50px;
    margin-left: 150px;
    border: 1px solid black;
    margin-top: 50px;
}

.p1-Complete-assign {
    float: left;
    margin-top: 50px;
    margin-left: 90px;
}

.p2-Complete-assign {
    margin-left: 60vh;
    margin-top: 50px;
}

.p3-Complete-assign {
    float: left;
    margin-top: 20px;
    margin-left: 90px;
}

.p4-Complete-assign {
    margin-left: 60vh;
    margin-top: 20px;
}

.p5-Complete-assign {
    margin-left: 60vh;
    margin-top: 20px;
}

.download-icon1 {
    height: 20px;
    width: 20px;
    margin-left: 0px;
    margin-top: 0px;
}

.p6-Complete-assign {
    margin-left: 30vh;
    margin-top: 30px;
    color: green;
}