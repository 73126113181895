* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.ass-btn {
  // background-color: black;
  color: #202224;
}

.Recent-message {
  background-color: #5C5AB6;
  // margin: 30px;
  padding: 30px 50px;
  color: white;
}

.Wallet-section {
  background-color: #EAE9FB;
  border-radius: 20px;
  // height: calc(100vh - 150px);
  margin: 30px;
}

.form-control-search {
  border: 1px solid black;
  margin-right: 2px;
  padding: 1px;
}

.recent-container {
  margin: 30px;
  background-color: #5c5ab6;
  color: white;
  height: 60px;
}

.chat-container {
  background-color: #EAE9FB;
  border-radius: 20px;
  // height: calc(100vh - 150px);
  // margin: 30px;
  // width: 100%;
}

.sidebar-head {
  text-align: center;
  font-size: 12px;
  padding-bottom: 20px;
  margin: 20px;

}

.mainSection_navbar {
  margin-top: 10px;
  margin-bottom: 10px;
}

.header-oval {
  height: 36px;
  background: white;
  color: #5C5AB6;
  border-radius: 52%;
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.head-dashboard {
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 0;
}

.head-videos {
  font-size: 14px;
  cursor: pointer;

  &.active {
    background: white;
    color: purple;
  }
}

.head-subject {
  font-size: 14px;
  cursor: pointer;

  &.active {
    background: white;
    color: purple;
  }
}

.pen-icon {
  margin-left: 10px;
  margin-right: 10px;
}

.ask-btn {
  margin-left: 150px;
  margin-top: 15px;
}

.noti-bell {
  margin-left: 10px;
}

.sub_component_section {
  background: #EAE9FB;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  height: 500px;
}

.Enroll-section {
  background-color: #EAE9FB;
  border-radius: 20px;
  // height: calc(100vh - 150px);
  margin: 30px;
}

.category {
  position: absolute;
  top: 8px;
  left: 12px;

  svg {
    color: #202224 !important;
    opacity: 0.7;
    font-size: 14px;
  }
}

.student-inp-grp-bg {
  background-image: linear-gradient(#5C5AB6, #2B62A8);
  background-color: #5C5AB6;
}

.loggedIn_container {
  display: flex;
}

.sidebar_container {
  width: 220px;
  height: 100vh;
  /* Make the sidebar take the full height of the viewport */
  background-color: #5C5AB6;
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.25);
  color: white;
  display: flex;
  flex-direction: column;
  // Add your other styling for the sidebar

  .sidebar_user_profile {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    height: 100px;
    width: 100px;
    margin: 70px auto 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    p {
      font-size: 13px;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .sidebar_content {
    // height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    // background-color: #EAE9FB;
    // padding-left: 25px;
    // gap: 20px;

    img {
      margin-left: 10px;
      margin-right: 10px;
      height: 25px;
      width: 25px;
    }

    &.active {
      background: white;
      color: #5C5AB6;
    }

    &:hover {
      cursor: pointer;
      z-index: 0;
      font-weight: 600;
    }
  }

  /* Set the sidebar to a fixed position */
  position: fixed;
}

.mainSection_container {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 220px;
  /* Adjust the margin based on your sidebar width */
  padding: 20px;
  /* Add padding to the main section as needed */
  overflow-y: auto;
  /* Enable scrolling for the main section if it overflows */
  // Add your other styling for the main section
}




// /* Tablet styles */
// @media screen and (max-width: 1024px) {
//   .loggedIn_container {
//     flex-direction: column;
//   }
// }

// /* Mobile styles */
// @media screen and (max-width: 768px) {
//   .sidebar_container {
//     width: 100%;
//     /* Take up full width on smaller screens */
//     min-width: auto;
//     /* Reset min-width */
//   }
// }

// /* Additional media queries for smaller screens if needed */

// /* Large screens */
// @media screen and (min-width: 1200px) {
//   /* Add any specific styles for large screens here */
// }



/* Tablet styles */
@media screen and (max-width: 1024px) {
  // .sidebar_container {
  //   display: none;
  // }

  .loggedIn_container {
    flex-direction: column;
  }

  // .mainSection_container {
  //   margin-left: 0px;
  // }



}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .sidebar_container {
    display: none;
  }

  .mainSection_container {
    margin-left: 0px;
  }





}