.view-assign-Head{
    padding-left: 50px;
    margin-top: 30px;
    color: #263238;
    font-family: Nunito Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.05px;
}
.view-assign-details{
   margin-left: 200px;
    font-size: 16px;
    margin-top: 50px;
    color: black;
    row-gap: 20px;
}
.p1-view-assign{
    float: left;
    padding: 0px 50px;
}
.view-assign-details1{
    margin-left: 200px;
     font-size: 16px;
     margin-top: 20px;
     color: black;
 }
 .p2-view-assign{
    margin-left: 250px;
    font-size: 16px;
 }