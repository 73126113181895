



// @media (min-width: 1024px) {
.details-table{
  background-color: #00B69B;
  border: none;
  color: white;

  cursor: pointer;

  
 
}
.details-table-assignment{
  background-color:#5C5AB6;
  border: none;
  color: white;
  padding: 5px;
  cursor: pointer;
  border-radius: 6px;
  
 
}

.data-table {
  // padding: 40px 18px;
  font-weight: 600;
}

.assignment-details-table {
  // padding: 40px 18px;

  .assignment-details {
    background-color: #FAFAFA;
    height: 500px;
    width:1000px;
  }
}
.details-table1{
  justify-content: center;
}
.icon-name,
.wallet-nav {
  color: white;
  background-color: #5C5AB6;
  height: 43px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.nav-signup {
  padding: 8px 255px 10px 40px
}

.faChevronLeft1 {
  padding: 8px 0px;
}

.asgmt-nav {
  font-size: 14px;
  padding: 10px;
}

.choose-nav {
  // background-color: #5C5AB6;
  color: white;
}

.sign_as {
  padding: 40px 0px;
  color: #263238;
  font-weight: 800;
}

.switch {
  position: relative;
  display: inline-block;
  width: 74px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #D7D6F2;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 28px;
  width: 28px;
  left: 8px;
  top: 1px;
  /* bottom: -11px; */
  background-color: white;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #D7D6F2;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.sign-up-login {
  text-decoration: none;
  color: #5C5AB6;

  font-weight: 400;
}

.login-btn {
  font-family: sans-serif;
  padding: 5px;
  width: 85%;
  border-radius: 5px;
  background-color: #5C5AB6;
  border: none;
  color: white;
  display: flex;
  justify-content: center;
}

.login-email,
.remember-me,
.switch-remember {
  align-items: center;
  justify-content: center;
}

.login-box {

  justify-content: center;
  align-items: center;
}

.remember-switch {
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px 0px;
}

.remember {
  color: #172B4D;
  font-weight: 400;
  font-size: 15px;
  padding: 5px;
  font-family: 'Open Sans';
}

.dont-account {
  text-align: center;
  font-family: 'Open Sans';
  color: #8392AB;
  font-size: 14px;
  padding: 8px;
}

.login-para {
  display: flex;
  align-items: center;
  color: #8392AB;
  font-weight: 400;
  font-size: 14px;
  padding: 10px 0px;
}

.email-input {
  padding: 10px 15px;
  width: 253px;
  border-radius: 10px;
  border: 2px solid #9E9E9E;
  outline: none;
}

.pwd-input {
  width: 253px;
  border-radius: 10px;
  border: 2px solid #9E9E9E;
  padding: 10px 15px;
  outline: none;
}

.login-password {
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
}

.logimg {
  width: 756px;
  height: 645px;
}

.search {
  margin-left: 2px;
}

.input-search {
  height: 26px;
  padding-left: 11px;
}

.search-bar {
  justify-content: flex-end;
  // margin-left: 771px;
  // padding-top: 30px;
}


.subject-open{
  background-color: #5C5AB6;
  border: none;
  color: white;
  padding: 3px;
  border-radius: 8px;
  width: 80px;
}
.search-icon {
  margin-left: 3px;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  padding-left: 4px;
  background: linear-gradient(135deg, #5C5AB6 0%, #2B62A8 100%);
}

.head-assignment {
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 0;
}

@mixin content_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 3px;
}

.profile-img {
  width: 30px;
  border-radius: 15px;
  // margin-left: 82px;
}

.log_container {
  display: flex;
  justify-content: center;
  padding: 20px 0px;
}
.progress-bar-student{
  background-color: #5C5AB6;

}
.progress{
  height: 3px ;
  border-radius: 0px;
}
.my-1{
height: 3px !important;
}
.percent{
  font-size: 10px;
  text-align: justify;
}
.open-button{
  justify-content: flex-end;
 
}
.rate-number{
  font-size: 8px;
}
.signIn_signUp {
  // @include content_box;

  //signIn

  .signIn_container {
    // @include content_box;
    // border: 1px solid rgba(48, 87, 123, 0.3);
    // box-shadow: 2px 2px 15px rgba(48, 87, 123, 0.7);
    // border-radius: 5px;
    // width: 500px;
    // height: 500px;
    // padding: 40px;

    // margin-bottom: 9pc;

    // .title {
    //   font-size: 28px;
    //   font-weight: 1000;
    // }

    // .input_fields {
    //   width: 100%;
    //   display: flex;
    //   flex-direction: column;
    //   gap: 20px;

    //   input {
    //     @include content_box;
    //     width: 100%;
    //     border-radius: 3x;
    //     height: 40px;
    //     padding: 0 20px;
    //   }
    // }

    .btn_section {
      display: flex;
      justify-content: space-between;
      width: 100%;
      gap: 20px;

      // button {
      //   width: 1000%;
      //   height: 40px;
      //   background-color: rgb(17, 80, 125);
      //   color: #ffffff;
      //   font-size: 20px;
      //   transition: 0.2s all linear;

      //   &:hover {
      //     cursor: pointer;
      //     background-color: rgba(17, 80, 125, 0.9);
      //   }
      // }
    }
  }

  //Register
  .register_container {
    // @include content_box;
    // gap: 30px;
    // border: 1px solid rgba(48, 87, 123, 0.3);
    // box-shadow: 2px 2px 15px rgba(48, 87, 123, 0.7);
    // border-radius: 5px;
    // width: 500px;
    // height: auto;
    // padding: 40px;

    // .title {
    //   font-size: 28px;
    //   font-weight: 1000;
    // }

    .input_container {
      width: 100%;

      // input {
      //   outline: 0;
      //   border-radius: 15px;
      //   &:focus {
      //     outline: 0;
      //   }
      // }

      // .input_error_box {
      //   border-color: red;
      // }

      // .input_focus_on_error {
      //   border-color: rgb(84, 84, 84);
      // }

      // input {
      //   width: 100%;
      //   height: 40px;
      //   padding: 0 20px;
      //   border-radius: 3px;

      //   &::placeholder {
      //     font-size: 16px;
      //   }
      // }

      .error_msg {
        font-size: 13px;
        font-weight: 600;
        color: white;
        position: relative;

        .input_error {
          // visibility: hidden;
          width: 220px;
          background-color: rgb(149, 54, 54);
          border: 1px solid rgb(144, 5, 5);
          color: #fff;
          text-align: center;
          border-radius: 6px;
          padding: 5px 10px;
          position: absolute;
          z-index: 1;
          top: -35px;
          right: 110%;
          padding: 5px 10px;

          &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 100%;
            margin-top: -8px;
            border-width: 8px;
            border-style: solid;
            border-color: transparent transparent transparent rgb(149, 54, 54);
          }

          &:hover {
            cursor: pointer;
          }

          .input_error_text {
            display: inline-block;
          }
        }

        .input_description {
          display: none;
          list-style-type: none;

          .password_description {
            list-style-type: "~ ";

            .password_includes {
              margin-left: 40px;
            }
          }
        }

        &:hover {
          .input_description {
            display: block;
            z-index: 2;
            position: absolute;
          }
        }
      }

      .hidden {
        display: none;
      }
    }

    .select_profession_container {
      .select_profession {
        display: flex;
        gap: 20px;
        align-items: center;
        justify-content: center;

        .am_student {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;

          input {
            &:hover {
              cursor: pointer;
            }
          }
        }

        .am_teacher {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;

          input {
            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      .select_profession_error_msg {
        text-align: right;
        color: red;
        font-size: 13px;
        font-weight: bolder;
      }
    }

    .btn_section {
      display: flex;
      justify-content: space-between;
      width: 100%;
      gap: 20px;
    }
  }
}
// }