.Add-another-accnt {

    color: #5C5AB6;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 30px;
    border: 2px solid #5C5AB6;
}

.accnt-details-card {
    background: whitesmoke;
    // margin-left: 250px;
    // width: 400px;
    // height: 180px;
    // margin-top: 80px;
    border-radius: 5px;
}

.table-wallet {
    background-color: #5C5AB6;
    border: 1px solid black;
}

.accnt-detail-head {
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.account-head{
    background-color: #6A6A6A;
}
.accnt-num {
    font-size: 15px;
    padding-left: 50px;
    padding-top: 30px;
}

.branch-detail {
    font-size: 15px;
    padding-left: 50px;
    padding-top: 30px;
}

.more-details {
    color: #226597;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;

}
.account-number{
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.btn-edit {
    background-color: #5C5AB6;
    border: 1px solid #5C5AB6;
    border-radius: 5px;
    border-width: 5px;
    color: whitesmoke;
    width: 10%;
    margin-left: 20px;
    margin-top: 20px;
}

.main-divHead-pop11 {

    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    border-radius: 20px;
    height: auto;
    margin: 30px;
    text-align: right;



}

.sub-Head1-pop {
    height: 50px;
    width: 70vh;
    background-color: #5C5AB6;
    padding-left: 10px;
    padding-top: 15px;
    color: whitesmoke;
    font-size: 14px;
    display: flex;
    border-radius: 10px;

}

.view-schedule-details11 {
    margin-left: 50px;
    font-size: 16px;
    margin-top: 50px;
    color: black;
    row-gap: 20px;
}

.p1-view-schedule {
    float: left;
    padding: 0px 50px;
}

.view-schedule-details22 {
    margin-left: 50px;
    font-size: 16px;
    margin-top: 20px;
    color: black;
}

.p2-view-schedule {
    margin-left: 200px;
}

.view-schedule-details33 {
    margin-left: 50px;
    font-size: 16px;
    margin-top: 20px;
    color: black;
}