* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito Sans", sans-serif;
  letter-spacing: 1px;
}

@mixin head_btn_style {
  height: auto;
  padding: 10px 20px;
  border-color: #ffffff;
  color: rgb(48, 87, 123);
  letter-spacing: 1px;
  &:hover {
    cursor: pointer;
  }
}

.landing_page {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  overflow: hidden;

  //Section 1

  .landing_navbar {
    align-items: center;
    padding: 0 70px 0 20px;
    height: 70px;
    background-color: #ffffff;
    color: rgb(48, 87, 123);
    width: 100vw;
    display: flex;
    justify-content: space-between;
    position: fixed;
    z-index: 3;
    // border-bottom: 1px solid rgb(48, 87, 123);
    box-shadow: 1px 1px 10px rgb(48, 87, 123);

    .navigation_content {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      align-items: center;
      color: rgb(48, 87, 123);

      .navigation {
        display: flex;
        justify-content: space-between;
        gap: 70px;
        font-weight: bold;

        p:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .sign_in {
        display: flex;
        align-items: center;
        gap: 20px;
        // color: rgb(48, 87, 123);

        button {
          // font-size: 13px;
          // font-weight: bold;
          border-radius: 30px;
          background-color: rgb(48, 87, 123);
          color: rgb(255, 255, 255);
          padding: 5px 10px;
          letter-spacing: 1px;
          font-weight: bolder;
          border-color: transparent;

          &:hover {
            cursor: pointer;
            // background-color: rgba(255, 255, 255, 0.3);
            text-decoration: none;
            // color: rgb(39, 189, 1);
          }
        }
      }
    }
  }
  // .fixed {
  //   position: fixed;
  //   // opacity: 0.5;
  //   z-index: 3;
  // }

  //Section 2

  .head_description {
    // background-image: url("../images/img-1.png");
    // background-repeat: no-repeat;
    // background-size: cover;
    // height: 550px;
    // position: relative;
    // overflow: hidden;

    .head_contents {
      position: absolute;
      right: -8%;
      bottom: 25%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 2vw;
      z-index: 2;

      .search-topic {
        color: aliceblue;
        float: right;
        // margin-right: 200px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1vw;

        h2 {
          width: 45%;
          font-size: 35px;
          font-weight: 1000;
        }
        p {
          width: 55%;
          font-size: 18px;
          line-height: 25px;
          font-weight: 500;
        }

        .search_input {
          display: flex;
          align-items: center;
          justify-content: start;
          overflow: hidden;

          .left {
            input {
              border-radius: 10px 0 0 10px;
              height: 40px;
              width: 250px;
            }
          }

          .right {
            font-weight: bold;
            width: 45px;
            background-color: #30577b;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0 10px 10px 0;

            .search_icon {
              font-size: 30px;
            }
          }
        }
      }
      .btn_section {
        display: flex;
        gap: 2vw;

        button {
          background-color: #30577b;
          @include head_btn_style;
          font-size: 15px;
          color: #ffffff;
        }

        // .demo_btn {
        //   @include head_btn_style;
        //   background-color: transparent;
        //   font-weight: bold;
        //   border: 1px solid #fff;
        //   font-size: 15px;
        //   color: #ffffff;
        // }
      }
    }
  }

  //Section 3

  .who_to_login_as_container {
    display: flex;
    gap: 50px;
    overflow: hidden;
    margin: 20px 40px 100px 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .multiple_user_selection {
      display: flex;
      flex-direction: column;
      
      // justify-content: center;
      align-items: center;
    }
  }

  //Choose Who You Are

  .identity_container {
    background: linear-gradient(180deg, #8280FF 0%, #3936CC 100%);
    box-shadow: 0px 3.48914px 3.48914px rgba(0, 0, 0, 0.25), inset 0px 3.48914px 3.48914px rgba(0, 0, 0, 0.25);
    border-radius: 12.7822px;
    width: 100%;
    height: 230px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;
    background-color: rgb(48, 87, 123);
    border-radius: 10px;
    box-shadow: #000;

    &:hover {
      cursor: pointer;
      background:linear-gradient(180deg, #2f2d2d 0%, #000 70%)
    }

    img {
      padding: 20px;
      width: auto;
      height: 140px;
    }

    h3 {
      font-size: 22px;
      font-weight: bold;
      color: #fff;
    }
  }

  //Section 4
  .choices_option_container {
    display: flex;
    padding-top: 120px;
    flex-direction: column;

    // justify-content: center;
    align-items: center;
    margin-bottom: 50px;

    .choices_head {
      // margin-right: 25px;
      font-size: 28px;
      font-weight: 700;
      color:black;
    }

    .choices_container {
      display: flex;
      flex-direction: column;
      // gap: 50px;

      .choices_row {
        display: flex;
        justify-content: space-between;
        // width: 60vw;

        .offerings {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
        }
      }
    }
  }

  //Section 5 Partner
  .partner_container {
    height: 350px;
    color: #ffffff;

    .partner_section {
      box-shadow: 0px 6px 60px rgba(38, 45, 118, 0.1);
    border-radius: 32px;
      background-image: url("../images/partner.jpg");
      background-position: 55% 25%;
      background-size: 100% auto;
      min-height: 100%;
      border-radius: 8px;
      

      // display: flex;
      // flex-direction: column;
      // align-items: center;
      // justify-content: center;
      // gap: 30px;

      .head {
        font-size: 38px;
        font-weight: 600;
      }

      img {
        height: 60px;
      }

      button {
      
        background-color: rgba(48, 88, 122, 0.7);
        color: #ffffff;
      border-radius: 6px;
      border: none;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

//Footer
  // .footer_container {
    // width: 100vw;
    // height: 300px;
    // padding: 30px 0 0 150px;
    // background-color: #30577b;
    // display: flex;
    // gap: 40px;
    // color: #ffffff;

    // .footer_col {
      // display: flex;
      // flex-direction: column;
      // gap: 10px;

      // h4 {
        // margin: 0 0 20px 0;
      // }
    // }
  // }
  .section__pading{
    padding: 4rem 4rem;
  }
  // .footer{
  //   background-color: rgb(117, 109, 165);
  // }
  .sb__footer{
    display: flex;
    flex-direction: column;
  }
  .sb__footer-links{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
    margin-bottom: 2rem;
    padding: 3.9rem;
  }
.sb__footer-links_div{
  width: 150px;
  margin: 1rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  color: white;
  
}
a{
  
  text-decoration: none;
}
.socialmedia{
  display: flex;
  flex-direction:row;
  padding: 0;
  justify-content: center;
}
.sb__footer-links_div h4{
  font-size: 16px;
  line-height: 9px;
  margin-bottom: 0.9rem;
}
.sb__footer-links_div p{
  font-size: 12px;
  line-height: 15px;
}
      }

//Scroll to top btn
.scrollToTop {
  position: fixed;
  bottom: 100px;
  font-size: 50px;
  left: 50px;
  display: block;

  .displayScrollToTopBtn {
    display: block;
  }

  .scrollToTopBtn {
    &:hover {
      // color: rgba(0, 0, 0, 0.5);
      cursor: pointer;
    }
  }
}






