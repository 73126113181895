.place-next-btn {
  border-radius: 4px;
  color: white;
  background: #5c5ab6;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  border: none;
}

.place-order-card {
  border-radius: 8px;
  background-color: #F5F6FA;
  box-shadow: 4px 4px 64px 0px rgba(0, 0, 0, 0.1);
  border: none;
}
.place-order-head {
  font-family: Nunito Sans;
  font-size: 28px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
}
.billing-head {
  color: #263238;
  font-family: Nunito Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.payment-head {
  color: #141212;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 25.786px;
  /* 120% */
}

.card-payment {
  background: #5c5ab6;
}

.billing-main-head {
  color: white;
  font-family: Nunito Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.payment-input-field {
  border: 1px solid #83839a;
  color: white !important;
  text-align: start;
  font-family: Nunito Sans;
  background-color: #5c5ab6 !important;
}

.payment-input-field {
  &::placeholder {
    color: #fff !important;
    font-family: Roboto !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 27.505px;
    /* 160% */
  }
}

.payment-button {
  color: #5c5ab6 !important;
  text-align: center;
  font-family: Roboto;
  font-size: 15.042px;
  font-style: normal;
  font-weight: 500;
  line-height: 17.191px;
  /* 114.286% */
  background-color: #fff !important;
  text-transform: uppercase;
}

.payment-head {
  color: #fff;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 25.786px;
  /* 120% */
}

.Sub-total {
  color: #fff;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.414px;
  /* 118.75% */
}

.vis-img {
  border-radius: 1.67px;
  background: #0e4595;
}

.master-img {
  border-radius: 1.67px;
  background: white;
}

@media only screen and (max-width: 767px) {
  .payment-input-field,
  .Sub-total {
    font-size: 12px;
  }

  .payment-button {
    font-size: 12px;
  }
}

/* Media query for screens between 768px and 991px (typical tablets) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .payment-input-field,
  .Sub-total {
    font-size: 14px;
  }

  .payment-button {
    font-size: 12px;
  }
}

/* Media query for screens between 992px and 1199px (larger desktops) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .payment-input-field,
  .Sub-total {
    font-size: 12px;
  }

  .payment-button {
    font-size: 12px;
  }
}

/* Media query for screens 1200px and larger (extra-large screens) */
@media only screen and (min-width: 1200px) {
  .payment-input-field,
  .Sub-total {
    font-size: 18px;
  }

  .payment-button {
    font-size: 20px;
  }
}

.place-container {
  margin-left: 23px;
  margin-top: 20px;
 
}

.cart-container {
  margin-left: 23px;
  margin-top: 20px;
}

.pay-btn {
  background-color: #5c5ab6;
  color: white;
  border: none;
}

.place-input {
  width: 100px;
  border-radius: 3px;
  outline: none;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  border-bottom-width: none !important;
}

td,
th {
  // border: 1px solid #dddddd;
  // text-align: center;
  // padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.placeorder-field {
  border-radius: 3.856px;
  border: 0.964px solid #000;
  background: #f5f5f5;
}

.p-placeholder{
  font-size: 12px;
}

.modal-placeholder{
background-color: #5c5ab6;
color: #dddddd;

}

.background-placeholder{
  background-color: white;
}
