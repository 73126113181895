.search-input {
  // width: 175%;
  background: #F5F6FA;
  border-radius: 19px;
  height: 38px;
  padding-left: 30px;
  border: 0.6px solid #D5D5D5;
  outline: none;
  // margin-left: 10px;
}

.ask-quotation {
  background-color: white;
  height: 30px;

  box-shadow: 0px 1.13158px 4.52632px rgba(0, 0, 0, 0.2);
  width: 124px;
}

.search-btn {
  height: 38px;
  font-size: 12px;
  background: #5C5AB6;
  border-radius: 3px;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // padding: 9px 14px;
  font-weight: 600;
  color: white;
  text-align: center;
}

.student-navbar {
  // margin-left: 35px;
}

.bell-icon {}


.navbar-name {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  color: #404040;
}

.faChevronDown-icon {}

.ask-book {}

.book-icon {}

.dropdown-options {
  border: none;
  background-color: transparent;
  color: #263238;

  .circle-dropdown {
    width: 18px;
    height: 18px;
    border: 1px solid #5C5C5C;
    border-radius: 50%;
    justify-content: center;
    display: flex;
    align-items: center;

    svg {
      font-size: 10px;
    }
  }
}

.want-to-search {
  color: #000;
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.123px;
}

.diver-options {
  color: #263238;
}

.dropdown-subhead {
  color: #263238;
  font-family: Nunito Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.diver-line {
  margin-left: 15px !important;
  margin-right: 15px !important;
  color: #404040;
}

.ul-for-options {
  color: #263238 !important;
  width: 200px !important;
  padding-top: 0px !important;
  padding-left: 70px !important;
}

// .teach-from-options {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-top: 10px;
//   margin-bottom: 15px;
.teach {
  color: #FFF !important;
  font-family: Nunito Sans !important;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.123px;
  background-color: #5C5AB6 !important;
}

// }
.student-teacher-part {
  color: #263238;
  font-family: Nunito Sans;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.65px;
}

.sub-Teach {
  border-radius: 4px;
  background: #5C5AB6;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  color: white;
}

.main-search-category {
  border-radius: 19px;
  border: 0.6px solid #D5D5D5;
  background-color: #F5F6FA !important;

  &::placeholder {
    color: #202224 !important;
  }
}

.user-name {
  color: #404040;
  font-family: Nunito Sans;
  font-size: 16Fpx;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.nav-img {
  width: 49px;
  height: 49px;
  border-radius: 50%;
  // display: flex;
  // align-items: center;
  justify-content: center;
}

.search-category {
  margin-left: 30px;
  width: 200px;
  border-radius: 23px;
  position: relative;
}

.Find-btn {

  background-color: #1978d6;
  color: white;
  border: none;
}

.student-nav {
  align-items: flex-end;
  justify-content: space-between;
}

.valid-course {
  color: #455A64;
  font-family: Nunito Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.teacher-label-input {
  color: #263238;
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.back-btn {
  border-radius: 4px;
  background-color: #FFF !important;
  border: 1px solid black !important;
  color: black !important;
}