.batch-schedule-mainDiv {
    background-color: whitesmoke;
    padding-left: 20px;
    margin-left: 300px;
    margin-top: 50px;
    width: 85vh;
    height: 180px;
}

.view-batch {
    background-color: rgb(119, 113, 113);
    margin-top: 20px;


}

.view-batch1 {
    background-color: rgb(226, 217, 217);
    margin-top: 80px;
    border-radius: 5px;
    padding: 10px;
    font-size: 15px;


}

.batch-main-table {
    width: 80vh;
    border-collapse: collapse;

}

.view-batch-td1 {
    background-color: rgb(255, 250, 250);
    margin-top: 20px;
    padding-left: 60px;
    padding-top: 20px;
    font-size: 14px;

}