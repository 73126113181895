.choices-card {
    width: 15rem;
    height: 8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #5C5AE9;
    border-radius: 14.6535px;
    color: white;
}

.choice-card-content {
    font-family: 'Poppins';
    font-weight: 600;
}
.blog-text{
    
}
// .dwnld-ios{
//     font-family: 'Poppins';
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     background: #FFFFFF;
//     box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
//     border-radius: 20px;
//     font-size: 10px;
//     color: #5C5AE9;
//     padding: 10px;
//     border: none;
//     font-weight: 600;
//     padding-left: 20px;
// }
.dwnld-btn,
.dwnld-android {
    display: flex;
    justify-content: center;
    padding-top: 20px;
}

.choice-content-bottom {
    font-family: 'Poppins';
    font-weight: 600;
    color: #5C5AE9;
}

.choices-card-bottom {
    width: 15rem;
    height: 8rem;
    background: #FFFFFF;
    border: 1.33214px solid #5C5AE9;
    margin-top: 20px;
}

.dwnld-android-btn {
    font-family: 'Poppins';
    background: #5C5AE9;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    color: white;
    border-radius: 20px;
    border: none;
    padding: 7px;
    font-size: 8px;
    font-weight: 600;

}

.mobile {
    position: relative;
}

.dwnld-now-btn {
    position: absolute;
    bottom: 29px;
    left: 45%;
    border: none;
    font-weight: 600;
    font-family: 'Poppins';
    font-size: 22px;
    color: white;

}

.mobile-icon {
    position: absolute;
    left: 4pc;
    bottom: 19px;
}

.mobile-img {
    width: 460px;
    height: auto;

}

// .image-cards{
//     display: flex;
// }
.two-cards {
    padding: 20px;
}

// .blog-head{
//     left: 3pc; 
// }
.card-img-top,
.card-blog-content {
    width: 260px;
    height: 190px;

    &.img-top {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), #D9D9D9;
    }

}

.card-text {
    font-family: 'Poppins';
    font-size: 13px;
    padding-top: 8px;
    font-weight: 400;

}

.card-blog-head {
    font-family: 'Poppins';
    justify-content: center;
}

.card-blog-content {
    background-color: #5C5AE9;
    ;
    width: 260px;
    font-size: 16px;
    color: white;

    padding-bottom: 6pc;
    padding-top: 5px;
}

.card-blog-img {
    width: 260px;
    height: 189px;
}

.Our-latest-Blog {
    font-family: 'Poppins';
    // margin-bottom: 51px;
    // margin-top: 90px;
    // margin-right: 38pc;
}

.hr-line {
    width: 65pc;
    border: 1px solid #D4D2E3;

}

.Asked-Question-1 {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 22px;

    &.active {
        color: #5C5AB6;

    }
}

.Asked-Question-2 {
    font-family: 'Poppins';
    padding-top: 10px;
    padding-bottom: 30px;
    font-weight: 500;
    color: #5C5AB6;
}

.Asked-Question-3 {
    font-family: 'Poppins';
    font-weight: 700;
}

.Asked-Question-head {
    font-family: 'Poppins';
    margin-bottom: 20px;
    margin-top: 90px;
}

.Asked-Question-para {
    font-family: 'Poppins';
    text-align: center;
    margin-bottom: 30px;
}

.asked-faMinus {
    align-items: center;
    justify-content: space-between;
}

.asked-faXmark {
    align-items: center;
    justify-content: space-between;
}

.faXmark-icon {
    color: #5C5AB6;
    font-size: 20px;
}

.read-more {
    margin-left: 9pc;
    margin-top: 5pc;
}

.faChevronRight {
    margin-top: 2px;
    margin-left: 4px;
}

.Read-more-link {
    font-family: 'Poppins';
    color: #FFFFFF;
}

.date-img {
    position: relative;
}

.date {
    font-family: 'Poppins';
    position: absolute;
    bottom: -1px;
    color: #5C5AB6;
    font-size: 13px;
    background-color: white;
}