.quote-job-details-div1 {
  
    background-color: whitesmoke;
    border: 1px dotted black;
   

}

.qoute_pop_up {
    font-size: 22px;
    border-radius: 20px;
    height: calc(80vh - 250px);
}

.pop_qoute {
    height: 50px;
    width: 75vh;
    background-color: #5C5AB6;
    padding-left: 20px;
    padding-top: 15px;
    color: whitesmoke;
    font-size: 14px;
    display: flex;
    border-radius: 10px;

}

.quote_frrelance_msg {
    margin-top: 80px;
}

.inpt_box_para1 {
    color: black;
}

.quote_msg_box {
    height: 80px;
    width: 300px;
    border: 1px solid black;
    outline: none;
    border-radius: 5px;
    margin-top: 10px;
}

.sub_quote_box {
    margin-top: 80px;
}

.inpt_box_para2 {
    color: black;
    margin-top: 20px;
    font-size: 12px;
    width: 120px;
}

.sub_quote_box {
    float: left;
}

.quote_sub_box {
    height: 30px;
    width: 50px;
    border: 1px solid black;
    outline: none;
}
.quote-btn3 {
    margin-top: 50px;
    font-size: 15px;
    color: rgb(255, 255, 255);
    margin-left: 50px;
    width: 10%;
    background-color: #5C5AB6;
    border: 1px solid #5C5AB6;
    border-radius: 10px;
    border-width: 5px;
    text-align: center;
}