.logo {
  color: white;
  height: 61px;
  width: 165px;
  cursor: pointer;
}

.nav-btn {
  font-weight: 700;
  background-color: #8280ff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  // padding: 4px;
  border: none;
  // height: 5vh;
}

.online-btn {
  background-color: rgb(253, 244, 244);
}

.nav-drop {
  font-weight: 700;
  cursor: pointer;
  // width: 100px;
  background-color: #8280ff;
  color: white;
  border: none;
  // height: 5vh;
  border-radius: 5px;

  &:focus {
    outline: none;
  }
}

.nav-drop-home {
  border-radius: 7px;
  background: #5C5AB6;
  font-weight: 700;
  cursor: pointer;
  // width: 100px;
  background-color: #8280ff;
  color: white;
  border: none;
  // height: 5vh;
  border-radius: 5px;

  &:focus {
    outline: none;
  }
}

.nav-h1.nav-h2,
.nav-h3,
.nav-h4,
.nav-h5 {
  background-color: #8280ff;
  cursor: pointer;
  border: none;
}

.nav1-drop {
  background-color: #8280FF;
  color: white;
  font-weight: 700;
  cursor: pointer;
  // width: 110px;
  // height: 5vh;
  border-radius: 5px;
  border: 2px solid #8280FF;
}

.nav1-h1.nav1-h2,
.nav1-h3 {
  background-color: #8280ff;
  color: black;
  cursor: pointer;
  border: 2px solid #7372f5;
}

.login {
  font-weight: 700;
  cursor: pointer;
  width: 60px;
  background-color: #8280ff;
  color: white;
  border-radius: 5px;
  border: none;
  font-size: 12px;
  height: 5vh;
}

.sign-up {
  font-weight: 700;
  font-size: 12px;
  cursor: pointer;
  width: 63px;
  background-color: #8280ff;
  color: white;
  border-radius: 5px;
  border: none;
  height: 5vh;
}

.landing_navbar {
  font-size: 13px;
}

.Contact_us {
  background-color: #8280ff;
  color: white;
  font-weight: 700;
  height: 5vh;
  border-radius: 5px;
  cursor: pointer;
  padding-top: 10px;
}

.black-bg {
  background-color: #FFF;
}

.btn-on-black {
  color: white !important;
  font-weight: 700 !important;
  background-color: #8280FF !important;
  padding: 5px 12px !important;
  border-radius: 7px !important;
}

.text-on-black {
  color: white !important;
  font-weight: 700 !important;
}

.login-btn-on-black {
  color: #8280FF !important;
  background-color: white !important;
  font-weight: 700 !important;
}

.signup-btn-on-black {
  color: white !important;
  background-color: #8280FF !important;
  font-weight: 700 !important;
}

.featured-subhead {
  color: #252525;
  font-family: Nunito Sans;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.18px;
}

.category-btn {
  background-color: white;
  color: #252525;
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.09px;
}


/* Add this CSS to your existing styles or create a new CSS file */

/* Media query for tablet screens and smaller */
@media screen and (max-width: 768px) {
  .navbar-items {
    display: none;
    /* Hide navigation items by default */
  }

  .show-nav .navbar-items {
    display: flex;
    /* Show navigation items when the navbar has the 'show-nav' class */
    flex-direction: column;
    /* Stack items vertically */
  }

  .navbar-toggle {
    display: block;
    /* Show the hamburger menu */
    cursor: pointer;
  }
}

/* Add this CSS for the responsive toggle */
.toggle-btn {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.toggle-btn span {
  background: #333;
  height: 3px;
  width: 25px;
  margin: 5px 0;
  display: block;
}

.nav-links {
  display: flex;
  gap: xxl-4;
}

@media screen and (max-width: 768px) {
  .toggle-btn {
      display: flex;
  }

  .nav-links {
      display: none;
      flex-direction: column;
      width: 100%;
      text-align: center;
      position: absolute;
      top: 60px;
      left: 0;
      background: #333;
  }

  .nav-links.show {
      display: flex;
  }

  .nav-links button,
  .nav-links select {
      border-bottom: 1px solid white;
  }
}
