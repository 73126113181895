.footer {
    background-color: #252641;
}

.footer-head {
    color: #B2B3CF;
    text-align: center;
  
  
}
/* Media query for screens smaller than 768px (typical smartphones) */
@media only screen and (max-width: 767px) {
    .footer-head {
        font-size: 10px;
    }

    .footer-copyright-bottom{
        font-size: 10px;
    }
    
  
  }
  /* Media query for screens smaller than 768px (typical smartphones) */
  
  
  /* Media query for screens between 768px and 991px (typical tablets) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-head {
        font-size: 14px;
    }

    .footer-copyright-bottom{
        font-size: 14px;
    }

  }
  
  /* Media query for screens between 992px and 1199px (larger desktops) */
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .footer-head {
        font-size: 14px;
    }

    .footer-copyright-bottom{
        font-size: 14px;
    }

  }
  
  /* Media query for screens 1200px and larger (extra-large screens) */
  @media only screen and (min-width: 1200px) {
    .footer-head {
        font-size: 20px;
    }

    .footer-copyright-bottom{
        font-size: 20px;
    }
  
  }
// .footer-input {
//     color: white;
//     background-color: #252641;
//     width: 280px;
//     height: 40px;
//     border: 1px solid #83839A;
//     border-radius: 80px;
//     padding: 15px;
//     color: #83839A;

// }

// .footer-sub {
//     width: 110px;
//     height: 40px;
//     border-radius: 20px;
//     color: white;
//     margin-left: 10px;
//     background: linear-gradient(180deg, #8280FF 0%, #3936CC 100%);
//     border: none;
// }

.footer-input-btn {
    justify-content: center;
    align-items: flex-start;
    // margin-bottom: 114px;

}

.footer-sub {
    // border-radius: 60px;
    background: linear-gradient(180deg, #8280FF 0%, #3936CC 100%);
    color: #FFF;
    font-family: Nunito Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: white;
    border: none;
}

.footer-sub-dash {
    // border-radius: 60px;
    background: #00B69B;
    color: #FFF;
    font-family: Nunito Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: white;
    border: none;
}

.footer-sub-dash-cancel {
    // border-radius: 60px;
    background:#FD5454;
    color: #FFF;
    font-family: Nunito Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: white;
    border: none;
}



.footer-input {
    &::placeholder {
        color: #83839A;
    }
  }
.footer-copyright-bottom {
   
    text-align: center;
   
    font-weight: bolder;
    color: #B2B3CF;
}

.sb__footer-below-link {
    margin-bottom: 20px;
    justify-content: center;

}

.link1 {
    color: #B2B3CF;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.88px;
}

.slash1,
.slash2 {
    color: #626381;
    ;
}

.social-media {
    justify-content: right;
    padding-right: 20px;
    padding-bottom: 20px;
}

.fb,
.insta,
.tw,
.yt,
.whats {
    color: white;
}