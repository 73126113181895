.settings-pwd {
  font-family: Nunito Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}
.pwd-label {
  font-family: Nunito Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  &::placeholder {
    color: #37474f;
  }
}
.pwd-require {
  font-family: Nunito Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #252525;
}
.pwd-guide {
  font-family: Nunito Sans;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}
.settings-noti {
  font-family: Nunito Sans;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
}
.settings-noti-on {
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.005em;
  text-align: left;
}
.slider {
  position: absolute;
  cursor: pointer;

  background-color: #d9d9d9;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  background-color: #d9d9d9;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #40ae44;
}

input:focus + .slider {
  box-shadow: 0 0 1px #40ae44;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
