.Learn-head{
width: 18rem;
height: 1;
justify-items: right;
border-radius: 5px;
background-color: white;
}.card:hover{
  transform: scale(1);
box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.img{
  width: 132px;
  height: 71px;
  position: absolute;
  bottom: 10.5pc;
  right: 7pc;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #D9D9D9;
border-radius: 17.8423px;
}
.topic{
  padding-bottom:5pc;
  display: flex;
  justify-content: center;
}
@import url('https://fonts.googleapis.com/css?family=Muli:400,400i,700,700i');
body{
  font-family: 'Muli', sans-serif;
  background:white;
}
.shell{
  padding:80px 0;
}
.wsk-cp-product{
  width: 310px;
  height: 230px;
  background:#fff;
  padding:15px;
  border-radius:6px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  position:relative;
  margin:20px auto;
}
.wsk-cp-img{
  width: 250px;
    height: 230px;
    position: absolute;
    top: -128px;
    border-radius: 17px;
    padding: 15px;
    transition: all 0.2s ease-in-out;
    z-index: 1;
}
.wsk-cp-img img{
  transition: all 0.2s ease-in-out;
  border-radius:6px;
}

.wsk-cp-product:hover {
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}



.wsk-btn:hover, .wsk-btn:focus, .wsk-btn:active{
  text-decoration:none;
  color:#fff;
}  
.desired-main-head{
display: flex;
align-items: center;
letter-spacing: 0.04em;
color: #263238;
margin: 20px;
}
.desired-card-left,.desired-card-right{
  margin-top: 8px;
  border-radius: 15px;
  padding: 70px 20px 40px 20px;
  box-shadow: 0px 3.56845px 25px rgba(0, 0, 0, 0.1);
  transition: .5s;
  align-items: center;
  display: flex;
  justify-content: center;
  // width: 30%;
  position: relative;
  
}

.left-join{
  display: flex;
  justify-content: center;
}
.desired-box {
  flex-direction: initial !important;
}
.desired-content{
  padding-top: 30px;
  .sub-head {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    // font-size: 18px;
    line-height: 34px;
    letter-spacing: 0.005em;
    color: #252525;
    text-align: center;
    margin-bottom: 10px;
  }
  .desired-p {
    // font-size: 16px;
    margin-bottom: 20px;
  } 
}
.aa {
  position: absolute;
  top: -70px;
  display: flex;

  .a {
    position: absolute;
    width: 85px;
    height: 85px;
    background: #5C5AE9;
    border-radius: 50%;
    left: -139px;
    top: -13px;
    z-index: 1;
  }
  .b {
    position: absolute;
    width: 150px;
    height: 150px;
    background: #5C5AE9;
    border-radius: 50%;
    left: -84px;
    top: -125px;
  }
  .c {
    position: absolute;
    width: 135px;
    height: 135px;
    background: #5C5AE9;
    border-radius: 50%;
    top: -65px;
    left: 33px;
  }
}

@media screen and (min-width: 1200px) {

.client-text{
  font-size: 24px;
}
  /* Add more styles as needed for extra-large screens */
}

