.fafacebook{
    color: #1877F2;
}
.FaTwitter{
   color: #1DA1F2;
}
.FaYoutube{
   color: #CD201F;
}
.FaInstagram{
   color:  #bc2a8d;
}
  
  
.subject-img{
    max-width: 50%;
    max-height: 50%;
}
.viewall{
    cursor: pointer; 
}
.rating-bg{
    background-color:#ecd9ed;
}
.p-rating-date{
    color: rgb(169, 175, 180);
}