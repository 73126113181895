.view-student-assign {
    padding-left: 50px;
    margin-top: 30px;
    color: #263238;
    font-family: Nunito Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.05px;
}

.student-info-head {
    font-size: 18px;
    text-align: center;
    padding: 5px 10px;
    width: auto;
    height: auto;
    background-color: whitesmoke;
    color: black;
    margin-left: 0px;
    margin-top: 50px;
}

.p3-view-assign {
    float: left;
    padding: 0px 50px;
    margin-top: 10px;
}

.p4-view-assign {
    margin-left: 250px;
    margin-top: 10px;
    font-size: 16px;
}

#inactive-td {
    color: orange;
}

.teacher-cardss {
    width: 120vh;
    height: auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .techer-card-typo {
        font-size: 12px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .techer-card-typo {
        font-size: 14px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .techer-card-typo {
        font-size: 16px;
    }
}

@media only screen and (min-width: 1200px) {
    .techer-card-typo {
        font-size: 18px;
    }
}