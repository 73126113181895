.main-head-quest {
    font-size: 22px;
    padding-left: 50px;
    margin-top: 50px;
    color: black;
}

.Question1 {
    margin-left: 100px;
    margin-top: 50px;
    font-size: 16px;
}

.Question-box {
    border: 1px solid black;
    width: 400px;
    height: 100px;
    float: left;
    margin-left: 100px;
}

.first-question {
    font-size: 12px;
    width: 350px;
    margin-left: 20px;
    padding-top: 10px;
}

.Question-box1 {
    border: 1px dashed black;
    width: 300px;
    height: 100px;
    margin-left: 700px;
}

#quest-upl {
    color: black;
    padding-top: 30px;
    padding-left: 40px;
}
.Question2 {
    margin-left: 100px;
    margin-top: 40px;
    font-size: 16px;
}
.hr1{
    width: 120vh;
}
.quest-opt-1{
    margin-left: 120px;
    float: left;
    font-size: 15px;
 
}
.option-box-a{
    border: 1px solid black;
    width: 200px;
    height: 30px;
 
    border-radius: 5px;
    margin-left: 150px;
    margin-top: 20px;
}
#option-check-box{
    margin-left: 10px;
    float: left;
    margin-top: 5px ;
}
.ans-1{
    font-size: 12px;
    margin-top: 5px;
    margin-left: 50px;
}
.btn-1-next{
    background-color: #5C5AB6;
    border: 1px solid #5C5AB6;
    border-radius: 7px;
   justify-content: center;
    padding: 5px 10px;
   align-items: center;
    color: white;
  
}
.next-button{
display: flex;
justify-content: center;
}


.correct-answer {
    background-color: #A6EDB9;
}

.incorrect-answer {
    color: red;
}
