.quotation-req-drop-box {
    margin-top: 30px;
    margin-left: 50px;
    float: left;
}

#quotation-req-head {
    font-size: 15px;
    margin-top: 30px;
}

#quotation-drop-box1 {
    width: 150px;
    border-radius: 5px;
    height: 30px;
}

.quotation-filter {
    padding-top: 10px;
    margin-top: 80px;
    font-size: 15px;
    color: rgb(255, 255, 255);
    font-size: 15px;
    margin-left: 50px;
    width: 10%;
    background-color: #5C5AB6;
    border: 1px solid #5C5AB6;
    border-radius: 5px;
    text-align: center;
}

.quote-status1 {
    color: green;
}

.quote-status2 {
    color: rgb(241, 17, 1);
}

.table_head {
    padding-top: 50px;
}
.btn_detail{
    background-color: #5C5AB6;
    outline: none;
    border: 1px solid #5C5AB6;
    font-size: 12px;
   border-radius: 5px;
   width: 80px;
   height: 30px;
   color: whitesmoke;
}