.resource-add {
    width: 330px;
    margin-left: 20px;
    height: 40px;
    outline: none;
    margin-top: 30px;
    border: 1px solid #5C5AB6;
    padding-top: 7px;
    border-radius: 50px;
    padding-left: 15px;
    color: rgb(0, 0, 0);
}

.btn-create-Notification22 {
    background-color: #5C5AB6;
    border: 1px solid #5C5AB6;
    border-radius: 5px;
    color: rgb(247, 247, 247);
    width: 15%;
    margin-top: 50px;
    height: 30px;

}
.select_link{
    border-radius: 5px;
    color: rgb(0, 0, 0);
    width: 15%;
    margin-top: 50px;
    height:auto;
    align-items: center;
    margin-left: 75vh;
}

.btn-create-Notification23 {
    background-color: #5C5AB6;
    border: 1px solid #5C5AB6;
    border-radius: 5px;
    color: rgb(247, 247, 247);
    width: 10%;
    margin-top: 50px;
    height: 30px;

}

.resource-div {
    align-items: center;
    text-align: center;
}