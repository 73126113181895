.main-quote-head-div1 {
    color: black;
    font-size: 22px;
   
}

.quote-job-details-div {
    height: 130vh;
    width: 120vh;
    background-color: whitesmoke;
    border: 1px dotted black;
    margin-left: 120px;
    margin-top: 10px;

}

.quote-para1 {
    font-size: 15px;
    padding-left: 80px;
    padding-top: 20px;
    float: left;

}

.quote-para2 {
    font-size: 15px;
    margin-left: 450px;
    padding-top: 20px;
    width: 400px;

}

.quote-para3 {
    font-size: 15px;
    margin-left: 450px;
    padding-top: 20px;
    width: 400px;
    color: green;
}

.quote-btn {
    margin-top: 20px;
    font-size: 15px;
    color: rgb(255, 255, 255);
    margin-left: 90vh;
    width: 10%;
    background-color: #5C5AB6;
    border: 1px solid #5C5AB6;
    border-radius: 10px;
    border-width: 5px;
    text-align: center;
}

.quote-para4 {
    font-size: 15px;
    margin-left: 80px;
    padding-top: 20px;

}

#quote-checkbox1 {
    float: left;
    font-size: 15px;
    margin-left: 180px;
    margin-top: 30px;
}

.checkbox-quote-para1 {
    float: left;
    font-size: 15px;
    margin-left: 20px;
    margin-top: 26px;

}

.checkbox-quote-para2 {
    float: left;
    font-size: 15px;
    margin-left: 100px;
    margin-top: 26px;

}

#quote-inp-box1 {
    float: left;
    font-size: 15px;
    margin-top: 26px;
    margin-left: 15px;
    width: 45px;
    height: 20px;
    background-color: #d1d1d6;
    outline: none;
    border: 1px solid black;
}

#quote-inp-box0 {
    float: left;
    font-size: 15px;
    margin-top: 26px;
    margin-left: 5px;
    width: 50px;
    height: 20px;
    background-color: #d5d5db;


}

#quote-inp-box2 {
    font-size: 15px;
    margin-top: 26px;
    margin-left: 5px;
    width: 50px;
    height: 20px;
    background-color: #d5d5db;
}
.checkbox-quote-para3-wed{
    float: left;
    font-size: 15px;
    margin-left: 75px;
    margin-top: 26px;

}
#quote-inp-box3-wed{
    float: left;
    font-size: 15px;
    margin-top: 26px;
    margin-left: 15px;
    width: 45px;
    height: 20px;
    background-color: #d5d5db;
    outline: none;
    border: 1px solid black;
}
.checkbox-quote-para5-thur{
    float: left;
    font-size: 15px;
    margin-left: 90px;
    margin-top: 26px;

}
.checkbox-quote-para6-fri{
    float: left;
    font-size: 15px;
    margin-left: 120px;
    margin-top: 26px;
}
.checkbox-quote-para7-sun{
    float: left;
    font-size: 15px;
    margin-left: 110px;
    margin-top: 26px;

}
.teacher-quote-head{
    font-size: 15px;
    padding-top: 150px;
    color: black;
    margin-left: 150px;
}
#teacher-quote-msg{
    margin-left: 200px;
    margin-top: 20px;
    width: 300px;
    height: 100px;
    border-radius: 5px;

}
.quote-popup-teacher{
    float: left;
}
.teacher-quote-head2{
    font-size: 15px;
    padding-top: 50px;
    padding-left: 150px;

}
#teacher-quote-price{
    margin-left: 150px;
    margin-top: 20px;
    width: 130px;
    height: 30px;
    float: left;
    border-radius: 5px;

}
.teacher-quote-head3{
    font-size: 15px;
    padding-top: 50px;
    padding-left: 100px;
}
#teacher-quote-price1{
    margin-left: 100px;
    margin-top: 20px;
    width: 130px;
    height: 30px;
    float: left;
    border-radius: 5px;

}
.quote-btn2 {
    margin-top: 50px;
    font-size: 15px;
    color: rgb(255, 255, 255);
    margin-left: 370px;
    width: 10%;
    background-color: #5C5AB6;
    border: 1px solid #5C5AB6;
    border-radius: 10px;
    border-width: 5px;
    text-align: center;
}
.sub_quote_box1 {
    float: left;
    margin-left: 50px;
}