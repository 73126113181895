.Main-div-course {
    width: 300px;
    height: auto;
    background-color: rgb(255, 255, 255);
    margin-top: 60px;
    border-radius: 5px;

}

.book-img {
    border-radius: 8px !important;

}

.para-abt1 {

    color: #263238;
    font-family: Nunito Sans;

    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.btn-prev {
    display: inline-flex;
    padding: 5.841px 16.23px;
    justify-content: center;
    align-items: center;
    gap: 4.057px;
}

.des-para {
    color: #263238;

    font-family: Nunito Sans;
    font-size: 14.148px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.list-value {
    margin-top: 15px;
    margin-left: 20px;
    font-size: 15px;
}

.main-divHead2 {
    width: auto;
    height: auto;
    background-color: rgb(255, 255, 255);
    margin-top: 60px;


    border-radius: 5px;


}

.para-head {
    color: #263238;

    font-family: Nunito Sans;
    font-size: 25.015px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

}

.pic {
    height: 140px;
    width: 150px;
    margin-left: 10px;
    margin-top: 30px;
    border-radius: 50%;
    float: left;
}

.profile-name {
    margin-top: 60px;
    margin-left: 200px;
    color: #263238;

    font-family: Nunito Sans;
    font-size: 19.473px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.389px;

}

.profile-bio {
    margin-top: 50px;
    float: left;
    color: #263238;

    font-family: Nunito Sans;
    font-size: 19.473px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.389px;
}

.profile-bio11 {
    margin-top: 50px;
    float: left;

    margin-left: 220px;
    color: #263238;

    font-family: Nunito Sans;
    font-size: 19.473px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.389px;
}

.profile-bio1 {
    margin-top: 50px;
    margin-left: 350px;
    color: #263238;

    font-family: Nunito Sans;
    font-size: 19.473px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.389px;

}

.profile-bio12 {
    margin-top: 70px;
    margin-left: 350px;
    color: #263238;

    font-family: Nunito Sans;
    font-size: 19.473px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.389px;

}

.sche-div {
    margin-left: 10px;
    margin-top: 50px;
    color: #000;

    font-family: Nunito Sans;
    font-size: 14.429px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

}

.sche-div1 {
    margin-left: 10px;
    color: #000;

    font-family: Nunito Sans;
    font-size: 14.429px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

}

.day-time {
    float: left;
    font-size: 12px;
    margin-left: 30px;
    margin-top: 10px;

}

.day-time1 {
    float: left;
    font-size: 12px;
    margin-left: 20px;
    margin-top: 10px;


}

.review-head {
    margin-top: 30px;
}

.para-review-head {
    font-size: 18px;
    margin-left: 10px;
}

.review-box {
    border: 1px solid black;
    font-size: 12px;
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
}

.rating_name {
    font-size: 16px;
    font-weight: 600;
    font-family: Nunito Sans;
    line-height: 14.81px;
}

.review_para{
    font-size: 13px;
    font-weight: 600;
    font-family: Nunito Sans;
    line-height: 10.86px;
}

.student_profile{
    width: 2rem;
    height: 2rem;
}
.div-head-view {
    background-color: #fff;
}

.div-head-view2 {
    background-color: #fff;
}

@media only screen and (max-width: 767px) {
    .para-abt1 {
        font-size: 10px;
    }

    .outline-btn-Teacher {
        font-size: 10px;
    }

    .view_sub_topic {
        font-size: 10px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .para-abt1 {
        font-size: 12px;
    }

    .outline-btn-Teacher {
        font-size: 12px;
    }

    .view_sub_topic {
        font-size: 12px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .para-abt1 {
        font-size: 12px;
    }

    .outline-btn-Teacher {
        font-size: 12px;
    }

    .view_sub_topic {
        font-size: 13px;
    }
}

@media only screen and (min-width: 1200px) {
    .para-abt1 {
        font-size: 14px;
    }

    .outline-btn-Teacher {
        font-size: 14px;
    }

    .view_sub_topic {
        font-size: 14px;
    }
}

.outline-btn-Teacher {
    background: #FFFFFF;
    border: 2px solid #5C5AB6 !important;
    color: #5C5AB6;
    cursor: pointer;
}