.inp-bank-detail {
    float: left;
    margin-left: 350px;
    margin-top: 50px;
}

#head1-bank-detail {
    font-size: 18px;
}

#inp-select-details {
    width: 200px;
    height: 30px;
    border-radius: 5px;
    outline: none ;
    border: none ;
   
}

.inp-bank-detail2 {
    margin-left: 90vh;
    margin-top: 50px;
    
}

.inp-bank-detail3 {
    margin-left: 350px;
    margin-top: 50px;
}

.btn-update {
    background-color: #5C5AB6;
    border: 1px solid #5C5AB6;
    border-radius: 5px;
    border-width: 5px;
    color: whitesmoke;
    width: 15%;
    margin-left: 90vh;
    margin-top: 10px;
}