.partner-btn {

    font-family: 'Poppins';
    background: linear-gradient(180deg, #8280FF 0%, #3936CC 100%);
}

.partner-section {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55));
    box-shadow: 0px 6px 60px rgba(38, 45, 118, 0.1);
    border-radius: 32px;
    border: none;
    background-repeat: no-repeat;
}

.head {
    font-family: 'Poppins';
}

.landing-page-button {
    font-family: 'Poppins';
    background: linear-gradient(180deg, #8280FF 0%, #3936CC 100%);
    color: #FFF;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


.landing-page-font {
    color: #FFF;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.landing-page-font1 {
    color: #FFF;
    text-align: center;
    font-family: Nunito Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.48px;
}


@media only screen and (max-width: 767px) {
    .partner_section {
        background-repeat: no-repeat;
    }

    .landing-page-font1 {
        font-size: 11px;
    }

}


@media only screen and (min-width: 768px) and (max-width: 991px) {
    .partner_section {
        background-repeat: no-repeat;
    }

    .landing-page-font1 {
        font-size: 14px;
    }
}


@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .partner_section {
        background-repeat: no-repeat;
    }

    .landing-page-font1 {
        font-size: 14px;
    }
}


@media only screen and (min-width: 1200px) {
    .partner_section {
        background-repeat: no-repeat;
    }
}