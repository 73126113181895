.btn-add-test {
    background-color: #5C5AB6;
    border: 1px solid #5C5AB6;
    border-radius: 5px;
    border-width: 5px;
    color: whitesmoke;
    font-size: 18px;
}

.teacher-table-test {
    margin-top: 50px;
    // padding-left: 100px;

}

.head-test-table {
    background-color: #5C5AB6;
    width: 100px;
    font-size: 15px;
    color: whitesmoke;
    height: 50px;
    border-radius: 1px solid black;
}

.main-table-test {

    border: 1px solid black;
    height: 50px;
}

.td-test-table {
    width: 100px;
    font-size: 15px;
    color: black;
    height: 50px;
    border-radius: 1px solid black;
    text-align: center;
}

.view-assignment1 {
    height: 20px;
    width: 20px;

}