.cancel-test-btn1 {
    font-size: 15px;
    margin-top: 30px;
    border-radius: 5px;
    // background-color: #5C5AB6;
    height: 30px;
    border: none;
    outline: none;
    width: 100px;
    margin-left: 55vh;
    border: 1px solid #5C5AB6;
}

.cancel-test-btn1-v {
    font-size: 15px;
    margin-top: 30px;
    border-radius: 5px;
    // background-color: #5C5AB6;
    height: 30px;
    border: none;
    outline: none;
    width: 100px;
    border: 1px solid #5C5AB6;
}


.Quest-test-btn1 {
    font-size: 15px;
    // margin-top: 30px;
    border-radius: 5px;
    // background-color: #5C5AB6;
    height: 30px;
    border: none;
    outline: none;
    // width: 150px;
    // margin-left: 100px;
    border: 1px solid #5C5AB6;
}

