.card-contactus{
    background-color: #FFF;
}
.get-in-touch{
    background-color: #252641;
}
.label-contact-us{
   color: #fff;
}
.location-contact{
    background-color: #62dcfa;
}