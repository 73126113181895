.enroll-video
{background-color: #263238;
color: white;
padding: 10px;
margin-top: 10px;
width: 100%;
}
.view-profile-link{
    // color: white;
}
.Question-1{
    margin-top: 10px;
}
.profile-video-player {
    background-color: #1e211f;
    height: 120px;

}
.player-video{
    // height:500px;
}